import { useTheme } from "@mui/material";
import { Box, Checkbox, CircleLoader, Icon, Input } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { fetchFinanceStructureRequest, updateFinanceStructureRequest } from "@src/components/redux/actions/financeStructureActions";
import { Stack } from "@src/components/stack";
import { FinanceStructureWrap } from "./finance-structure.style"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { FinanceStructureModifyDialog } from "../finance-structure-modify-popup/finance-structure-modify-popup-dialog.component"

export const FinanceStructure = () => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const { data, updatedData, loading, error } = useSelector((state: any) => state?.financeStructure);
  const [financeStructureState, setFinanceStructureState] = useState<any>({});

  const { reference_number } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setFinanceStructureState(data);
    }
  }, [data]);

  useEffect(() => {
    if (reference_number) {
      dispatch(fetchFinanceStructureRequest(reference_number));
    }
  }, [dispatch, reference_number]);

  const handleOpenDialog = () => setOpened(true);
  const handleCloseDialog = () => setOpened(false);

  const handleInputChange = (field: string, value: any) => {
    setFinanceStructureState((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleInputBlur = (field: string) => {
    const updatedField = { [field]: financeStructureState[field] };
    dispatch(updateFinanceStructureRequest({ ...data, ...updatedField }));
  };

  const fields = [
    { title: "Finance Type", key: "finance_type" },
    { title: "Asset Cost", key: "selling_price", startAdornment: "$" },
    { title: "Down Payment", key: "down_payment_value", startAdornment: "$" },
    { title: "Tax on Asset cost", key: "tax_amount", startAdornment: "$" },
    { title: "Tax Treatment", key: "Tax_Treatment" },
    { title: "Net Trade-in Value", key: "net_finance_amount", startAdornment: "$" },
    { title: "Fees, charge & discount", key: "Fees, charge & discount", startAdornment: "$" },
    { title: "Financed Amount", key: "Financed_Amount", startAdornment: "$" },
    { title: "Rebate", key: "rebate", startAdornment: "$" },
    { title: "Discount", key: "estimated_monthly_payment", startAdornment: "$" },
    { title: "GAP", key: "gap", startAdornment: "$" },
    { title: "Warranty", key: "insurance", startAdornment: "$" },
    { title: "Insurance", key: "backend_fees", startAdornment: "$" },
    { title: "Annual Admin Fees", key: "Annual Admin Fee", startAdornment: "$" },
    { title: "Documentation Fee", key: "documentation_fee", startAdornment: "$" },
    { title: "Option to Purchase Fee", key: "Option to Purchase Fee", startAdornment: "$" },
    { title: "Backend Fees", key: "backend_fees", startAdornment: "$" },
    { title: "Total Backend Fees", key: "total_backend_fees", startAdornment: "$" },
    { title: "Dealer Fees", key: "dealer_fees", startAdornment: "$" },
  ];

  return (
    <>
      <FinanceStructureWrap theme={theme} className="finance-structure-wrap"> 
        <Stack
          title={"Finance Structure"}
          paddingXs={2}
          paddingMd={2}
          actionArea={
            <a href="javascript:void(0)" className="text-primary primary-alert dFlex alignItemsCenter" onClick={handleOpenDialog}>
              Add/Modify <Icon name="ArrowLinkIcon" />
            </a>
          }
        >
          {loading ? (
            <CircleLoader theme={theme} size="sm" />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
                  <Box theme={theme} className="checkbox-section">
                    <Box theme={theme} className="empty"></Box>
                    <Checkbox theme={theme} className="checkbox" label={'Active'} />
                  </Box>
                  <Box theme={theme} className="child-scrollable-container">
                    <BasicList
                      input
                      options={fields.map((field) => ({
                        title: field.title,
                        titleBold: true,
                        action: (
                          <Input
                            theme={theme}
                            type="text"
                            startAdornment={field.startAdornment || ""}
                            value={financeStructureState?.[field.key] ?? "N/A"}
                            onChange={(e) => handleInputChange(field.key, e.target.value)}
                            onBlur={() => handleInputBlur(field.key)}
                          />
                        ),
                      }))}
                    />
                  </Box>
            </>
          )}
        </Stack>
      </FinanceStructureWrap>
      <FinanceStructureModifyDialog open={open} closeDialog={handleCloseDialog} />
    </>
  );
};

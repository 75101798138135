import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const MyNotesWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.my-notes-wrap": {
      ".notes-container": {
        display: 'flex',
        alignItem: 'flex-start',
        gap: 16,
      },
      '.scrollable-container': {
        maxHeight: 'calc(100vh - 162px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        ".notes-listing": {
          columnCount: 2,
          ".my-notes-list-wrap": {
            marginBottom: 16
          }
        }
      },
    },
}));

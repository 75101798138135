import { useTheme } from "@mui/material";
import {
  Box,
  Select,
  Typography,
  Button,
  DateRange,
} from "@ntpkunity/controls";
import { WorkqueueFilterWrap } from "./workqueue-filters.style";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  fetchWorkqueueRequest,
  updateDateFilter,
  updateSortOrder,
  updateWorkqueueFilters,
} from "@src/components/redux/actions/workQueueActions";
import searchIcon from "@assets/icons/searchIcon.svg";
import refreshIcon from "@assets/icons/refreshIcon.svg";
import resetIcon from "@assets/icons/resetIcon.svg";
import upAndDownIcon from "@assets/icons/upDownArrow.svg";
import calendarIcon from "@assets/icons/calendarIcon.svg";
import email from "@assets/icons/email.svg";

export const WorkqueueFilters = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { workQueues = [], pagination, filters } = useSelector(
    (state: any) => state.workQueue
  );

  const [localFilters, setLocalFilters] = useState(filters);
  const [hasSelectedDate, setHasSelectedDate] = useState(false);

  const uniqueStatuses = Array.from(
    new Set(workQueues.map((item) => item.status ?? "Unknown"))
  );

  const statusOptions = [{ text: "All Queues", value: "all" }, ...uniqueStatuses.map((status) => ({ text: status, value: status }))];

  const sortOptions = [
    { text: "Sort By", value: "sort", disabled: true },
    { text: "Ascending", value: "asc" },
    { text: "Descending", value: "desc" },
  ];

  const handleFilterChange = (key: string, value: any) => {
    const updatedFilters = { ...localFilters };

    if (value === "all" || value === null) {
      delete updatedFilters[key];
    } else {
      updatedFilters[key] = value;
    }

    setLocalFilters(updatedFilters);
    dispatch(updateWorkqueueFilters(updatedFilters));
    dispatch(fetchWorkqueueRequest(1, pagination.pageSize, updatedFilters));
  };

  const handleStatusChange = (event: any) => {
    handleFilterChange("status", event?.target?.value ?? null);

  };

  const handleSortChange = (event: any) => {
    const selectedSortOrder = event?.target?.value;

    if (selectedSortOrder !== "sort") {
      handleFilterChange("sort_by", selectedSortOrder);
    } else {
      handleFilterChange("sort_by", null);
    }
  };

  const handleDateRangeChange = (range: any) => {
    let startDate: string | null = null;
    let endDate: string | null = null;

    if (Array.isArray(range) && range.length === 2) {
      startDate = range[0] ? new Date(range[0]?.$d || range[0]).toISOString().split("T")[0] : null;
      endDate = range[1] ? new Date(range[1]?.$d || range[1]).toISOString().split("T")[0] : null;
    }

    setHasSelectedDate(!!(startDate && endDate));

    if (startDate && endDate) {
      dispatch(updateDateFilter(startDate, endDate));
      handleFilterChange("created_at_from", startDate);
      handleFilterChange("created_at_to", endDate);
    }
  };

  return (
    <WorkqueueFilterWrap theme={theme} className="table-widget">
      <Box theme={theme} className="custom-dropdown">
        <Typography theme={theme} className="text-body2 text-muted" component={"symbol"}>
          Select Category:
        </Typography>
        <Select
          theme={theme}
          disablePortal={false}
          items={statusOptions}
          onChange={handleStatusChange}
          value={localFilters.status ?? "all"}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        />
      </Box>

      <Box theme={theme} className="multi-functions">
        <Box theme={theme} className="emails unread">
          <Box theme={theme} position={"relative"} display={"flex"}>
            <Box theme={theme} className="notify"></Box>
            <img src={email} alt="email" />
          </Box>
          <Typography theme={theme} variant="body2" className="text-muted" component={"symbol"}>
            Unread
          </Typography>
        </Box>

        <Box theme={theme} className={`datepicker ${hasSelectedDate ? "expanded" : ""}`}>
          <DateRange
            theme={theme}
            onChange={handleDateRangeChange}
            disablePortal={true}
            placeholder={"Calendar"}
            endAdornment={<img src={calendarIcon} className="calendar-icon" />}
            render={<input placeholder="Calendar" readOnly />}
          />
        </Box>

        <Box theme={theme} className="custom-dropdown sort-btn">
          <img src={upAndDownIcon} alt="Sort Icon" className="sort-icon" />
          <Select
            theme={theme}
            disablePortal={false}
            onChange={handleSortChange}
            items={sortOptions}
            value={localFilters.sort_by ?? "sort"}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          />
        </Box>

        <Button
          theme={theme}
          defaultBtn
          iconText={<img src={resetIcon} alt="Reset" />}
          onClick={() => {
            const resetFilters = {
              status: "all",
              sort_order: "sort",
              created_at_from: null,
              created_at_to: null,
            };
            setLocalFilters(resetFilters);
            setHasSelectedDate(false);

            dispatch(updateWorkqueueFilters(resetFilters));
            dispatch(updateSortOrder("updated_at", null));
            dispatch(updateDateFilter(null, null));

            dispatch(fetchWorkqueueRequest(1, pagination.pageSize, {}));
          }}
        />

        <Button
          theme={theme}
          defaultBtn
          iconText={<img src={refreshIcon} alt="Refresh" />}
          onClick={() => {
            dispatch(fetchWorkqueueRequest(1, pagination.pageSize, localFilters));
          }}
        />
        <Box theme={theme} className="search">
          <Button theme={theme} defaultBtn iconText={<img src={searchIcon} alt="Search" />} />
        </Box>
      </Box>
    </WorkqueueFilterWrap>
  );
};
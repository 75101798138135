
import { useTheme } from "@mui/material"
import { MyNotesListWrap } from "./my-notes-list.style"
import { Stack } from "@src/components/stack";
import { Box, Icon, Label, Typography } from "@ntpkunity/controls";
import { UserName } from "../../user-name";
import { MyNotesListProps } from "./my-notes-list.props"

export const MyNotesList = () => {
    const theme = useTheme();

    return(
        <>
            <MyNotesListWrap theme={theme} className="my-notes-list-wrap">
                <Stack paddingMd={1} paddingXs={1} className="stack-user-header">
                    <Stack paddingMd={1} paddingXs={1} bgLight>
                        <UserName userTitle="JL" userName="Jordan Lee" subTitle="updated dealer profile." />
                    </Stack>
                    <Box theme={theme} className="notes-content">
                        <Box theme={theme} className="notes-head small-view">
                            <Typography theme={theme} variant="caption" component={'small'} className="fw-sbold text-body1">Credit History</Typography>       
                            <Label theme={theme} text="General" state="primary" />                     
                        </Box>
                        <Box theme={theme} className="notes-body">
                            <Typography theme={theme} variant="body2" component={'span'} className="text-body1 text-muted">
                                The applicant's credit score is 720, indicating a good credit rating. They have a history of timely repayments on previous loans, 
                                including a personal loan of $15,000 repaid over 3 years and a car.
                            </Typography>
                            <Box theme={theme} className="date-time">
                                <Typography theme={theme} variant="caption" component={'small'} className="caption-text text-muted month">
                                    Aug 00, 0000
                                </Typography>
                                <Typography theme={theme} variant="caption" component={'small'} className="caption-text text-muted">
                                    Aug 00, 0000
                                </Typography>
                            </Box>
                        </Box>
                        <Box theme={theme} className="notes-footer" pb={1}>
                            <a href="javascript:void(0)" className="text-primary">Add to Sticky Notes</a>
                        </Box>
                    </Box>
                </Stack>
            </MyNotesListWrap>
        </>
    )
}
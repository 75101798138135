import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const ActivitiesAndNotesSlidePopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.activities-and-notes-slide-popup-wrap": {
      position: 'fixed',
      top: 0,
      right: '-100%',
      bottom: 0,
      width: 'calc(100% - 0px)',
      transition: '0.8s',
      zIndex: 2,
      "&.open": {
        transform: 'translateX(64px) translateX(-100%)'
      },
      ".activities-and-notes": {
        padding: 16,
        borderRadius: '24px 0 0 24px',
        backgroundColor: theme.palette.common.white,
        width: 'calc(100% - 64px)',
        height: '100%',
        ".audit-trail-wrap, .my-notes-wrap": {
          ".audit-trail-stack, .my-notes-stack": {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'baseline',
            gap: 16,
            ".stack-header": {
              ".title": {
                ".MuiTypography-root": {
                  fontSize: theme.typography.body1.fontSize,
                  fontWeight: theme.customVariables.fontWeightSemiBold,
                }
              },
              ".action-area": {
                ".MuiButtonBase-root": {
                  fontSize: theme.typography.body2.fontSize,
                  color: theme.palette.grey[600],
                  ".MuiButton-endIcon": {
                    marginLeft: 4,
                  },
                  "@media(min-width: 1599.95px)": {
                    fontSize: theme.typography.body1.fontSize,
                    fontWeight: theme.typography.body1.fontWeight,
                  }
                }
              }
            },
            "&.w-100": {
              ".stack-header": {
                width: "100%"
              }
            },
          }
        }
      }
        
    },
    ".btn-dark-anim": {
      position: 'fixed',
      right: '-32px',
      top: 0,
      bottom: 0,
      zIndex: 2,
      "&.icon-only": {
        padding: '0px !important',
        svg: {
          width: 'auto !important',
          height: 'auto !important',
          path: {
            fill: theme.palette.common.black,
            stroke: theme.palette.common.white + "!important"
          }
        }
      },
      "&.hide": {
        right: '-1px'
      }
    },
    
  ".btn-light-anim": {
        borderRadius: 0,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        "&.icon-only": {
          padding: '0px !important',
          svg: {
            width: 'auto !important',
            height: 'auto !important',
            path: {
              stroke: 'unset !important',
              '&:last-child': {
                stroke: '#808080 !important'
              }
            }
          }
        },
        "&.show": {
          left: -32,
        },
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },

    
}));


export const SlideOutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.slide-out": {
      backgroundColor: theme.palette.common.black,
      position: 'fixed',
      right: '-60px',
      left: '100%',
      top: 0,
      bottom: 0,
      zIndex: 1,
      opacity: '0.8',
      transition: 'transform 0.5s ease-out',
      display: 'flex',
      "&.show": {
        left: '0px'
      },
    }
}));
import { useTheme } from "@mui/material";
import { Button, CircleLoader, Grid, Icon } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import { useEffect } from "react";
import { ApplicantDetailDialog } from "../applicant-detail-dialog/applicant-detail-dialog.component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  openApplicantDialog,
  closeApplicantDialog,
  fetchApplicantDetailsRequest,
} from "@src/components/redux/actions/applicantDetailsActions";

export const ApplicantDetailsStack = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isOpen, applicantDetails, loading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  const { reference_number } = useParams();

  useEffect(() => {
    if (reference_number) {
    }
    dispatch(fetchApplicantDetailsRequest(reference_number));
  }, [dispatch, reference_number]);

  const handleOpenDialog = (reference_number) => {
    dispatch(openApplicantDialog(reference_number));
  };

  const handleCloseDialog = () => {
    dispatch(closeApplicantDialog());
  };

  const fields = [
    {
      title: "Applicant Name:",
      key: () =>
        `${applicantDetails?.customer_info?.first_name || ""} ${
          applicantDetails?.customer_info?.last_name || ""
        }`,
    },
    {
      title: "Address:",
      key: () =>
        applicantDetails?.customer_info?.customer_addresses?.[0]
          ?.address_line_1 || "N/A",
    },
    {
      title: "Residence Time:",
      key: () =>
        applicantDetails?.customer_info?.customer_addresses?.[0]
          ?.move_in_date || "N/A",
    },
    {
      title: "Housing Type:",
      key: () =>
        applicantDetails?.customer_info?.customer_addresses?.[0]
          ?.address_type || "N/A",
    },
    {
      title: "Date of Birth:",
      key: () => applicantDetails?.customer_info?.date_of_birth || "N/A",
    },
    {
      title: "NIC Number:",
      key: () => applicantDetails?.customer_info?.ssn || "N/A",
    },
    {
      title: "Employer Name:",
      key: () => applicantDetails?.customer_info?.company_name || "N/A",
    },
    {
      title: "Employment Status:",
      key: () => applicantDetails?.customer_license?.[0]?.status || "N/A",
    },
    {
      title: "Employment Time:",
      key: () => applicantDetails?.customer_info?.updated_at || "N/A",
    },
  ];

  return (
    <>
      <Stack
        title={"Applicant Details"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={
              <>
                <Icon name="ArrowLinkIcon" />
              </>
            }
            onClick={() => handleOpenDialog(reference_number)}
          />
        }
      >
        {loading ? (
          <CircleLoader theme={theme} size={"sm"} />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <BasicList
            options={fields.map((field) => ({
              title: field.title,
              subtitle: typeof field.key === "function" ? field.key() : "N/A",
            }))}
          />
        )}
      </Stack>
      <ApplicantDetailDialog open={isOpen} closeDialog={handleCloseDialog} />
    </>
  );
};


import { useTheme } from "@mui/material"
import { AuditTrailWrap } from "./audit-trail.style"
import { Stack } from "@src/components/stack";
import { Button, Icon } from "@ntpkunity/controls";
import { AuditTrailList } from "../audit-trail-list/audit-trail-list.component"

export const AuditTrail = () => {
    const theme = useTheme();

    return(
        <>
            <AuditTrailWrap theme={theme} className="audit-trail-wrap">
                <Stack bgLight paddingMd={2} paddingXs={2} title="Audit Trail" className="audit-trail-stack">
                    <Button 
                        theme={theme}
                        primary
                        text={'Filters'}
                        startIcon={<><Icon name="FilterIconV4" /></>}
                    />
                    <Stack paddingMd={1} paddingXs={1} fullWidth className="scrollable-container">
                        <AuditTrailList />
                    </Stack>
                </Stack>
            </AuditTrailWrap>
        </>
    )
}
import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const RiskAssessmentStackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.risk-assessment-wrap": {
    ".graph": {
        position: 'relative',
        width: 181,
        height: 181,
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 'auto',
        ".graph-text": {
            marginTop: '-38px'
        }
    }
  },
}));


import { useTheme } from "@mui/material";
import { Box, Button, Grid, Icon, Label } from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { AlertStatusText, BasicStack } from "@src/components/dashboard"
import { ThirdPartyResponseList } from "../third-party-response-list/third-party-response-list"
import { Stack } from "@src/components/stack";
import { useState } from "react";

export const ThirdPartyResponse = () => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpened(true);
  };

  const handleCloseDialog = () => {
    setOpened(false);
  };

  return (
    <>
      <Stack
        title={"Third Party Response"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={
              <>
                <Icon name="ArrowLinkIcon" />
              </>
            }
            onClick={handleOpenDialog}
          />
        }
      >
        <Box theme={theme} display={'flex'} flexDirection={'column'} gap={1} mt={2}>
            <ThirdPartyResponseList />
        </Box>
      </Stack>
    </>
  );
};

import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AddDecisionDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-decision-dialog-wrap": {
    ".stack-wrap": {
      padding: "12px 16px",
      ".stack-header": {
        ".title": {
          ".MuiTypography-root": {
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: theme.typography.caption.lineHeight
          }
        }
      }
    }
  },
}));

import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Typography,
  Grid,
  Dialog,
  Box,
  DuoTab,
  Icon,
  Textarea,
  DataTable,
  Button,
  Input,
  Checkbox,
} from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { Stack } from "@src/components/stack";
import { DataTableWrap } from "@src/styles/data-table-wrap";
import { ComplianceCheckListTableWrap } from "./compliance-checklist-table.style";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveComment } from "@src/components/redux/actions/complianceChecklistActions"; 

export const ComplianceCheckListTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const comments = useSelector((state: any) => state.compliance);

  const columns = [
    {
      key: "DocumentType",
      label: "",
      className: "fw-medium w-250",
    },
    {
      key: "DocumentName",
      label: "Verified",
      className: "w-90 fw-medium text-center",
    },
    {
      key: "UploadedBy",
      label: "Waved",
      className: "w-90 fw-medium text-center",
    },
    {
      key: "Timestamp",
      label: "Comments",
      className: "fw-medium",
    },
  ];

  const checklistItems = [
    {
      documentType: "Funding Documents Received and Signed by Lander",
      verified: true,
      waved: true,
      comments: comments[0] || "",
    },
    {
      documentType: "Insurance Documents Received and reviewed",
      verified: false,
      waved: false,
      comments: comments[1] || "",
    },
    {
      documentType: "Contract Documents Signed by Applicant",
      verified: false,
      waved: false,
      comments: comments[2] || "",
    },
    {
      documentType: "Asset Invoice Received and Verified",
      verified: true,
      waved: true,
      comments: comments[3] || "",
    },
    {
      documentType: "Add-Ons invoice Received and Verified",
      verified: true,
      waved: true,
      comments: comments[4] || "",
    },
    {
      documentType: "Commission Invoice Received",
      verified: false,
      waved: false,
      comments: comments[5] || "",
    },
    {
      documentType: "Salary Slip Reviewed and Verified",
      verified: false,
      waved: false,
      comments: comments[5] || "",
    },
    {
      documentType: "Asset Inspection Documents Received",
      verified: true,
      waved: true,
      comments: comments[6] || "",
    },
    {
      documentType: "Home Address Verified",
      verified: false,
      waved: false,
      comments: comments[7] || "",
    },
    {
      documentType: "Bank Statement Verified",
      verified: false,
      waved: false,
      comments: comments[8] || "",
    },
  ];

  const [checkItems, setChecklistItems] = useState<any>(checklistItems);

  const handleCommentChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedItems = [...checkItems];
    updatedItems[index].comments = event;
    setChecklistItems(updatedItems);
    setFocusedInput(`input-${index}`);
  };

  const handleSaveComment = (index: number) => {
    const comment = checkItems[index].comments;
    dispatch(saveComment(index, comment));
    setFocusedInput(null);
  };

  return (
    <>
      <ComplianceCheckListTableWrap
        theme={theme}
        className="compliance-checklist-table-wrap"
      >
        <DataTableWrap theme={theme} className="data-table-wrap customize-table">
          <DataTable
            theme={theme}
            theadChildren={
              <>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.key} className={column.className}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </>
            }
            tbodyChildren={
              <>
                {checkItems.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="fw-medium w-250 text-wrap-normal">{row.documentType}</TableCell>
                    <TableCell className="w-90">
                      <Box theme={theme} display={"flex"} justifyContent={"center"}>
                        <Checkbox theme={theme} label={undefined} checked={row.verified} />
                      </Box>
                    </TableCell>
                    <TableCell className="w-90">
                      <Box theme={theme} display={"flex"} justifyContent={"center"}>
                        <Checkbox theme={theme} label={undefined} checked={row.waved} />
                      </Box>
                    </TableCell>
                    <TableCell className="editable-cell text-right">
                      <Box theme={theme} className="cell-content" justifyContent={"flex-end"}>
                        <Input
                          type="text"
                          fullWidth={true}
                          theme={theme}
                          placeholder="Type here..."
                          value={row.comments}
                          onChange={(e) => handleCommentChange(index, e)}
                          onBlur={() => setFocusedInput(null)}
                        />
                        {focusedInput === `input-${index}` && (
                          <>
                            <Button
                              defaultBtn
                              theme={theme}
                              iconText={<Icon name="TickIcon" />}
                              onClick={() => handleSaveComment(index)}
                            />
                            <Button defaultBtn theme={theme} iconText={<Icon name="CloseBlack" />} />
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell className="text-wrap-normal" sx={{display: 'none'}}>
                      <Box theme={theme} className="multi-line-ellipsis"></Box>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            }
          />
        </DataTableWrap>
      </ComplianceCheckListTableWrap>
    </>
  );
};
import {
  FETCH_WORKQUEUE_REQUEST,
  FETCH_WORKQUEUE_SUCCESS,
  FETCH_WORKQUEUE_FAILURE,
  UPDATE_SORT_ORDER,
  UPDATE_FILTERS,
  UPDATE_PAGINATION,
  EDIT_WORKQUEUE_REQUEST,
  EDIT_WORKQUEUE_SUCCESS,
  EDIT_WORKQUEUE_FAILURE,
  DELETE_WORKQUEUE_REQUEST,
  DELETE_WORKQUEUE_SUCCESS,
  DELETE_WORKQUEUE_FAILURE,
} from "../actions/workQueueActions";

const initialState = {
  loading: false,
  workQueues: [],
  error: null,
  filters: {},
  sortOrder: { column: null, order: null },
  pagination: {
    pageNumber: 0,
    pageSize: 50,
    totalRecords: 0,
  },
}; 

const workqueueReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WORKQUEUE_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_WORKQUEUE_SUCCESS:
      return {
        ...state,
        loading: false,
        workQueues: action.payload.results ?? [],
        pagination: {
          ...state.pagination,
          totalRecords: action.payload.totalRecords,
        },
      };
    case FETCH_WORKQUEUE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_SORT_ORDER:
      return { ...state, sortOrder: action.payload };
    case UPDATE_FILTERS:
      return { ...state, filters: action.payload };
    case UPDATE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageNumber: action.payload.pageNumber,
          pageSize: action.payload.pageSize,
        },
      };
    default:
      return state;
  }
};

export default workqueueReducer;
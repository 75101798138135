import { useTheme } from "@mui/material";
import { Box, Input } from "@ntpkunity/controls";
import { useState, useEffect } from "react";

export const WorkqueueFilter = ({ columnKey, updateFilter, value }) => {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState(value);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (searchTerm !== value) {
        updateFilter(columnKey, searchTerm);
      }
    }, 1000); 

    return () => clearTimeout(delayDebounce);
  }, [searchTerm, columnKey, updateFilter, value]);

  const handleChange = (event) => {
    setSearchTerm(event);
  };

  return (
    <Box theme={theme} className="table-filter">
      <Input
        theme={theme}
        fullWidth={true}
        type="text"
        placeholder="Search..."
        onChange={handleChange}
        value={searchTerm}
      />
    </Box>
  );
};
import { useTheme } from "@mui/material"
import { ActivitiesWrap } from "./activities.style"
import { Box, Accordion, Typography, Avatar, Label, SelectionButton } from "@ntpkunity/controls"
import { UserName } from "../user-name/index"

export const Activities = () => {
    const theme = useTheme();

    return(
        <>
            <ActivitiesWrap theme={theme} className="activities-wrap">
                <Box theme={theme} className="accordion-box child">
                    <Accordion
                        theme={theme}
                        items={[
                        {
                            content: (
                            <>
                                <Box theme={theme} className="content small-view">
                                    <Label
                                        theme={theme}
                                        text="Resubmitted"
                                        state="primary"
                                        textTransform="none"
                                    />
                                    <SelectionButton 
                                        theme={theme} 
                                        buttonItems={[
                                            {
                                                title: 'Sample Content',
                                                state: 'unavailable'   
                                            },
                                            {
                                                title: 'Sample Content',
                                                state: 'unavailable'   
                                            }
                                        ]}
                                    />
                                </Box>
                            </>
                            ),
                            title: <><UserName userTitle="FT" userName="Emily Parker" subTitle="updated Compliance and Regulatory." /></>,
                            key: "asset-1",
                            isExpanded: true,
                            id: "asset-1",
                            ariaControls: "asset-1",
                        },
                        {
                            content: (
                            <>
                                <Box theme={theme} className="content small-view">
                                    <Label
                                        theme={theme}
                                        text="Resubmitted"
                                        state="primary"
                                        textTransform="none"
                                    />
                                    <SelectionButton 
                                        theme={theme} 
                                        buttonItems={[
                                            {
                                                title: 'Sample Content',
                                                state: 'unavailable'   
                                            },
                                            {
                                                title: 'Sample Content',
                                                state: 'unavailable'   
                                            }
                                        ]}
                                    />
                                </Box>
                            </>
                            ),
                            title: <><UserName userTitle="FT" userName="William Harris" subTitle="updated Access Control Logs." /></>,
                            key: "asset-2",
                            isExpanded: false,
                            id: "asset-2",
                            ariaControls: "asset-2",
                        },
                        {
                            content: (
                            <>
                                <Box theme={theme} className="content small-view">
                                    <Label
                                        theme={theme}
                                        text="Resubmitted"
                                        state="primary"
                                        textTransform="none"
                                    />
                                    <SelectionButton 
                                        theme={theme} 
                                        buttonItems={[
                                            {
                                                title: 'Sample Content',
                                                state: 'unavailable'   
                                            },
                                            {
                                                title: 'Sample Content',
                                                state: 'unavailable'   
                                            }
                                        ]}
                                    />
                                </Box>
                            </>
                            ),
                            title: <><UserName userTitle="FT" userName="William Harris" subTitle="updated Access Control Logs." /></>,
                            key: "asset-3",
                            isExpanded: false,
                            id: "asset-3",
                            ariaControls: "asset-3",
                        },
                        ]}
                        shouldCollapse
                    />
                </Box>
            </ActivitiesWrap>
        </>
    )
}
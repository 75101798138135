import { styled } from "@mui/material";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const BasicListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.basic-list-wrap": {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    "&.text-nowrap": {
      ".list": {
        ".MuiTypography-root": {
          "&.subtitle": {
            whiteSpace: "wrap",
          },
        },
      },
    },
    ".list": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: '0px 8px',
      gap: 4,
      ".MuiTypography-root": {
        width: "50%",
        "&.title": {
          color: "#474A59",
          minWidth: 150
        },
        "&.subtitle": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "&.text-right": {
            textAlign: "right",
          },
        },
        
      },
      ".action-area": {
        ".u-form-group": {
          ".u-form-control": {
            backgroundColor: "#F7F7F7",
            ".MuiInputBase-input": {
              padding: 8
            },
            fieldset: {
              border: 'none'
            }
          }
        },
      }
    },
    "&.input": {
      gap: 8,
      ".list": {
        display: "flex",
        alignItems: "flex-start",
        alignSelf: "stretch",
        flex: "1 0 0",
        gap: 16,
        ".title": {
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          alignSelf: "stretch",
          minWidth: 175,
          maxWidth: 191,
          padding: 8,
          "@media(max-width: 1599.95px)": {
            minWidth: 126,
            maxWidth: 152,
          },
        }
      },
      ".action-area": {
        ".u-form-group": {
          ".u-form-control": {
            color: theme.palette.grey[700],
            fontSize: theme.typography.body1.fontSize,
            paddingLeft: 8,
            "@media(max-width: 1599.95px)": {
              fontSize: theme.typography.body2.fontSize,
            },
            ".adornment-icon": {
              marginRight: 0
            }
          }
        },
      }
    },
    "&.accordion": {
      
    }
    
  },
}));

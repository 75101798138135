import { useTheme } from "@mui/material"
import { RiskAssessmentGraphWrap } from "./risk-assessment-graph.style"
import { Image, Box, Typography, Label } from "@ntpkunity/controls"
import graph from "@src/assets/icons/assessment-graph.svg"
import axios from "axios"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

export const RiskAssessmentGraph = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const creditCheckScore = useSelector((state: any) => state.riskAssessment.credit_check_score);
    const running_workflow_instance_id = useSelector((state: any) => state.workQueue.running_workflow_instance_id);

    useEffect(() => {
        const fetchCreditScore = async () => {
            if (!running_workflow_instance_id) return;

            try {
                const base_url = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${base_url}/cde/get_activity_workflow_data`, {
                    params: {
                        workflowInstanceId: running_workflow_instance_id,
                        activity_name: "credit_score",
                    },
                });

                const score = response.data || "--";

                if (score === null) {
                    console.warn("Credit score not found in the API response");
                }

                dispatch({
                    type: "SET_CREDIT_SCORE",
                    payload: score,
                });
            } catch (err) {
                console.error("Error fetching credit score:", err);
            }
        };

        fetchCreditScore();
    }, [running_workflow_instance_id, dispatch]);


    return(
        <>
            <RiskAssessmentGraphWrap theme={theme} className="risk-assessment-graph-wrap">
                <Box theme={theme} mb={2}>
                    <Label
                        theme={theme}
                        text="Risk Tier 2"
                        state="primary"
                        textTransform="none"
                    />



                </Box>
                <Box theme={theme} className="graph">
                    <Image theme={theme} src={graph} alt={graph} />
                    <Box theme={theme} className="graph-text">
                        <Typography theme={theme} variant="caption" component={"small"} className="credit-score">Credit Score</Typography>
                        <Box theme={theme} display={'flex'}>
                            <Typography theme={theme} variant="h4" component={"h4"} className="text-primary">{creditCheckScore ? creditCheckScore : "--"}</Typography>
                            <Typography theme={theme} variant="h4" component={"h4"} className="number">/<span>710</span></Typography>
                        </Box>
                    </Box>
                    <Box theme={theme} mt={3} mb={1.5}>
                        <Label
                            theme={theme}
                            text="Excellent"
                            state="primary"
                            textTransform="none"
                        />
                    </Box>
                </Box>
            </RiskAssessmentGraphWrap>
        </>
    )
}
import { useTheme } from "@mui/material"
import { AiAssistantListWrap } from "./ai-assistant-list.style"
import { Accordion, Box, Typography, Input } from "@ntpkunity/controls";
import { SwotAnalysis } from "./swot-analysis/swot-analysis.component" 
import swotImg from "@src/assets/icons/ai-swot-analysis.svg"
import applicantIcon from "@src/assets/icons/applicant-icon.svg"
import assetIcon from "@src/assets/icons/asset-icon.svg"
import stipIcon from "@src/assets/icons/stipulation-icon.svg"
import financeIcon from "@src/assets/icons/finance-icon.svg"
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack} from "@src/components"

export const AiAssistantList = () => {
    const theme = useTheme();

    return(
        <>
            <AiAssistantListWrap theme={theme} className="ai-accordion-wrap">
                <Accordion
                    theme={theme}
                    items={[
                    {
                        content: (
                        <>
                           <SwotAnalysis items={[
                            {
                                title: 'Strengths',
                                status: 'strengths',
                                listing: [
                                    {
                                        title: 'Credit History'
                                    },
                                    {
                                        title: 'Income Capability'
                                    },
                                    {
                                        title: 'Employment History'
                                    }
                                ]
                            },
                            {
                                title: 'Weaknesses',
                                status: 'weaknesses',
                                listing: [
                                    {
                                        title: 'High Debt Ratio'
                                    },
                                    {
                                        title: 'Fraud Alerts'
                                    },
                                    {
                                        title: 'Asset Verification Issues'
                                    },
                                    {
                                        title: 'High Loan Ratio'
                                    }
                                ]
                            },
                            {
                                title: 'Opportunities',
                                status: 'opportunities',
                                listing: [
                                    {
                                        title: 'High Debt Ratio'
                                    },
                                    {
                                        title: 'Fraud Alerts'
                                    },
                                    {
                                        title: 'Asset Verification Issues'
                                    },
                                    {
                                        title: 'High Loan Ratio'
                                    }
                                ]
                            },
                            {
                                title: 'Threats',
                                status: 'threats',
                                listing: [
                                    {
                                        title: 'Fraud Alert Risk'
                                    },
                                    {
                                        title: 'Tech Sector Volatility'
                                    },
                                    {
                                        title: 'Asset Verification Issues'
                                    }
                                ]
                            }
                           ]} />
                        </>
                        ),
                        title: 
                        <>
                            <Box theme={theme} display={'flex'} alignItems={'center'} gap={1}>
                                <img src={swotImg} />
                                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium caption-text">SWOT Analysis</Typography>
                            </Box>
                        </>,
                        key: "1",
                        isExpanded: true,
                        id: "1",
                        ariaControls: "1",
                    },
                    {
                        content: (
                        <>
                           <SwotAnalysis items={[
                            {
                                title: 'Applicant Employment Insights',
                                success: true,
                                listing: [
                                    {
                                        title: 'Position and Time at Employer'
                                    },
                                    {
                                        title: 'Future Growth Prospects'
                                    }
                                ]
                            },
                            {
                                title: 'Employment Trends',
                                error: true,
                                listing: [
                                    {
                                        title: 'Job Market Conditions'
                                    },
                                    {
                                        title: 'Job Growth Projections'
                                    }
                                ]
                            },
                            {
                                title: 'Crime Trends',
                                error: true,
                                listing: [
                                    {
                                        title: 'Crime Statistics in San Francisco'
                                    },
                                    {
                                        title: 'Public Safety Issues'
                                    }
                                ]
                            },
                            {
                                title: 'Financial Trends',
                                error: true,
                                listing: [
                                    {
                                        title: 'Property Values and Real Estate'
                                    },
                                    {
                                        title: 'Household Income & Investment Patterns'
                                    }
                                ]
                            },
                            {
                                title: 'Company Insights',
                                error: true,
                                listing: [
                                    {
                                        title: 'Industry Trends and Reputation'
                                    },
                                    {
                                        title: 'Economic Context'
                                    }
                                ]
                            }
                           ]} />
                        </>
                        ),
                        title: 
                        <>
                            <Box theme={theme} display={'flex'} alignItems={'center'} gap={1}>
                                <img src={applicantIcon} />
                                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium caption-text">Applicant Insights</Typography>
                            </Box>
                        </>,
                        key: "2",
                        isExpanded: true,
                        id: "2",
                        ariaControls: "2",
                    },
                    {
                        content: (
                        <>
                           <SwotAnalysis items={[
                            {
                                title: 'Risk Indicators',
                                error: true,
                                listing: [
                                    {
                                        title: 'Residual Value Trends'
                                    },
                                    {
                                        title: 'Liquidity Risks'
                                    },
                                    {
                                        title: 'Depreciation Rate'
                                    }
                                ]
                            },
                            {
                                title: 'Ownership Costs',
                                listing: [
                                    {
                                        title: 'Total Cost of Ownership'
                                    },
                                    {
                                        title: 'Retention Index'
                                    }
                                ]
                            },
                            {
                                title: 'Market Insights',
                                success: true,
                                listing: [
                                    {
                                        title: 'Used Car Market Insights'
                                    },
                                    {
                                        title: 'Brand Stability Index'
                                    }
                                ]
                            },
                            {
                                title: 'External Market Influences',
                                success: true,
                                listing: [
                                    {
                                        title: 'Macroeconomic Indicators'
                                    },
                                    {
                                        title: 'Supply Chain Stability'
                                    }
                                ]
                            },
                            {
                                title: 'Competitive Benchmarking',
                                success: true,
                                listing: [
                                    {
                                        title: 'Segment-Specific RV Trends'
                                    },
                                    {
                                        title: 'Feature Premium Index'
                                    }
                                ]
                            }
                           ]} />
                        </>
                        ),
                        title: 
                        <>
                            <Box theme={theme} display={'flex'} alignItems={'center'} gap={1}>
                                <img src={assetIcon} />
                                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium caption-text">Asset Insights</Typography>
                            </Box>
                        </>,
                        key: "3",
                        isExpanded: true,
                        id: "3",
                        ariaControls: "3",
                    },
                    {
                        content: (
                        <>
                           <Box theme={theme} className="accordion-box ai-accordion-child">
                                <Accordion
                                        theme={theme}
                                        items={[
                                            {
                                                content: (
                                                    <>
                                                        <SwotAnalysis items={[
                                                            {
                                                                title: 'Interest Rate is High.',
                                                                notVerify: true,
                                                                description: 'Negotiate a lower interest rate to reduce monthly obligations.',
                                                                recommendation: 'Recommendation',
                                                                actionArea: <>
                                                                     <Typography theme={theme} variant="caption" component={'small'} className="fw-medium label caption-text">Interest Rate: 5%</Typography>
                                                                </>,
                                                                linkText: 'Create Stipulation'
                                                            },
                                                            {
                                                                title: 'Debt to Income ratio is High.',
                                                                notVerify: true,
                                                                description: 'Extend the loan duration to reduce the monthly payment.',
                                                                recommendation: 'Recommendation',
                                                                actionArea: <>
                                                                     <Typography theme={theme} variant="caption" component={'small'} className="fw-medium label caption-text">Terms: 48 Months</Typography>
                                                                </>,
                                                                linkText: 'Create Stipulation'
                                                            }
                                                        ]} />
                                                    </>
                                                ),
                                                title: 'Financial Stipulations',
                                                key: "1",
                                                isExpanded: true,
                                                id: "1",
                                                ariaControls: "1",
                                            },
                                            {
                                                content: (
                                                    <>
                                                        <SwotAnalysis items={[
                                                            {
                                                                title: 'Sample Stipulation',
                                                                notVerify: true,
                                                                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
                                                                recommendation: 'Recommendation',
                                                                actionArea: <>
                                                                     <Typography theme={theme} variant="caption" component={'small'} className="fw-medium label caption-text">Sample Suggestion</Typography>
                                                                </>,
                                                                linkText: 'Create Stipulation'
                                                            },
                                                            {
                                                                title: 'Sample Stipulation',
                                                                notVerify: true,
                                                                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
                                                                recommendation: 'Recommendation',
                                                                actionArea: <>
                                                                     <Typography theme={theme} variant="caption" component={'small'} className="fw-medium label caption-text">Sample Suggestion</Typography>
                                                                </>,
                                                                linkText: 'Create Stipulation'
                                                            }
                                                        ]} />
                                                    </>
                                                ),
                                                title: 'Non Financial Stipulations',
                                                key: "2",
                                                isExpanded: true,
                                                id: "2",
                                                ariaControls: "2",
                                            }
                                        ]}
                                />
                           </Box>
                        </>
                        ),
                        title: 
                        <>
                            <Box theme={theme} display={'flex'} alignItems={'center'} gap={1}>
                                <img src={stipIcon} />
                                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium caption-text">Stipulations Suggestions</Typography>
                            </Box>
                        </>,
                        key: "4",
                        isExpanded: true,
                        id: "4",
                        ariaControls: "4",
                    },
                    {
                        content: (
                        <>
                           <Stack paddingMd={1.25} paddingXs={1.25} className="recommended-finance-structure">
                            <BasicList
                                    noWrap
                                    options={[
                                    {
                                        title: "Finance Type",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Vehicle Price",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Rebate",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Discount",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Sales Tax ",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Down Payment ",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Deferred Down Payment",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Net Trade-in Value",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Total Vehicle Sales Price",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "GAP",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Warranty",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Insurance",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Backend Fees",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Total Backend Fees",
                                        action: (
                                            <>
                                              <Input
                                                theme={theme}
                                                type="text"
                                                startAdornment={"$"}
                                                value={"N/A"}
                                                onChange={()=>{}}
                                              />
                                            </>
                                        ),
                                    },
                                    ]}
                                />
                           </Stack>
                        </>
                        ),
                        title: 
                        <>
                            <Box theme={theme} display={'flex'} alignItems={'center'} gap={1}>
                                <img src={financeIcon} />
                                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium caption-text">Recommended Finance Structure</Typography>
                            </Box>
                        </>,
                        key: "5",
                        isExpanded: true,
                        id: "5",
                        ariaControls: "5",
                    },
                    ]}
                    shouldCollapse
                />
            </AiAssistantListWrap>
        </>
    )
}
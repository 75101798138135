export const FETCH_WORKQUEUE_REQUEST = "FETCH_WORKQUEUE_REQUEST";
export const FETCH_WORKQUEUE_SUCCESS = "FETCH_WORKQUEUE_SUCCESS";
export const FETCH_WORKQUEUE_FAILURE = "FETCH_WORKQUEUE_FAILURE";

export const UPDATE_SORT_ORDER = "UPDATE_SORT_ORDER";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_PAGINATION = "UPDATE_PAGINATION";

export const EDIT_WORKQUEUE_REQUEST = "EDIT_WORKQUEUE_REQUEST";
export const EDIT_WORKQUEUE_SUCCESS = "EDIT_WORKQUEUE_SUCCESS";
export const EDIT_WORKQUEUE_FAILURE = "EDIT_WORKQUEUE_FAILURE";

export const DELETE_WORKQUEUE_REQUEST = "DELETE_WORKQUEUE_REQUEST";
export const DELETE_WORKQUEUE_SUCCESS = "DELETE_WORKQUEUE_SUCCESS";
export const DELETE_WORKQUEUE_FAILURE = "DELETE_WORKQUEUE_FAILURE";


// export const fetchWorkqueueRequest = () => ({
//   type: FETCH_WORKQUEUE_REQUEST,
// // payload,
// });

export const fetchWorkqueueRequest = (pageNumber = 0, pageSize = 50) => ({
  type: FETCH_WORKQUEUE_REQUEST,
  payload: { pageNumber, pageSize },
});

export const fetchWorkqueueSuccess = (data) => ({
  type: FETCH_WORKQUEUE_SUCCESS,
  payload: data,
});

export const fetchWorkqueueFailure = (error) => ({
  type: FETCH_WORKQUEUE_FAILURE,
  payload: error,
});

export const updateSortOrder = (column, order) => ({
  type: UPDATE_SORT_ORDER,
  payload: { column, order },
});

export const updateFilters = (filters) => ({
  type: UPDATE_FILTERS,
  payload: filters,
});

export const updatePagination = (pageNumber, pageSize) => ({
  type: UPDATE_PAGINATION,
  payload: { pageNumber, pageSize },
});

export const editWorkqueueRequest = (id, updatedData) => ({
  type: EDIT_WORKQUEUE_REQUEST,
  payload: { id, updatedData },
});

export const editWorkqueueSuccess = (updatedRow) => ({
  type: EDIT_WORKQUEUE_SUCCESS,
  payload: updatedRow,
});

export const editWorkqueueFailure = (error) => ({
  type: EDIT_WORKQUEUE_FAILURE,
  payload: error,
});

export const deleteWorkqueueRequest = (id) => ({
  type: DELETE_WORKQUEUE_REQUEST,
  payload: id,
});

export const deleteWorkqueueSuccess = (id) => ({
  type: DELETE_WORKQUEUE_SUCCESS,
  payload: id,
});

export const deleteWorkqueueFailure = (error) => ({
  type: DELETE_WORKQUEUE_FAILURE,
  payload: error,
});

import { useTheme } from "@mui/material"
import { AuditTrailWrap } from "./audit-trail.style"
import { Stack } from "@src/components/stack";
import { Button, Icon, Box, Select, Typography, DateRange } from "@ntpkunity/controls";
import { AuditTrailList } from "../audit-trail-list/audit-trail-list.component"
import { useState } from "react";

export const AuditTrail = () => {
    const theme = useTheme();
    const [selectedCategory, setSelectedCategory] = useState("all");

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    return (
        <>
            <AuditTrailWrap theme={theme} className="audit-trail-wrap">
                <Stack bgLight paddingMd={2} paddingXs={2} title="Audit Trail" className="audit-trail-stack">
                    <Box theme={theme} display={'flex'} alignItems={'center'} justifyContent={'end'} width={'100%'}>
                        {/* <Box theme={theme} className="custom-dropdown">
                            <Typography
                                theme={theme}
                                className="text-muted"
                                variant="body2"
                                component={"span"}
                                display={"block"}
                            >
                                Category:
                            </Typography>
                            <Select
                                theme={theme}
                                disablePortal={false}
                                items={[
                                    { text: "All", value: "all" },
                                ]}
                                onChange={handleCategoryChange}
                                value={selectedCategory}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            />
                        </Box> */}
                        <Box theme={theme} className="multi-functions">
                            <Box theme={theme} className="datepicker">
                                <DateRange
                                    theme={theme}
                                    onChange={() => { }}
                                    disablePortal={true}
                                    placeholder={"Today"}
                                    endAdornment={
                                        <Icon name="CalendarIcon" className="calendar-icon" />
                                    }
                                    render={<input placeholder="Today" readOnly />}
                                    onFocus={() => { }}
                                />
                                <Button
                                    theme={theme}
                                    defaultBtn
                                    iconText={<Icon name="ChevronDown" />}
                                    onClick={() => { }}
                                />
                            </Box>
                            <Button
                                theme={theme}
                                defaultBtn
                                className="icon-light"
                                iconText={<Icon name="ResetIcon" />}
                                onClick={() => { }}
                            />
                            <Button
                                theme={theme}
                                defaultBtn
                                className="icon-light"
                                iconText={<Icon name="RefreshIcon" />}
                                onClick={() => { }}
                            />
                            <Button
                                theme={theme}
                                defaultBtn
                                iconText={<Icon name="SearchIcon" />}
                                onClick={() => { }}
                            />
                        </Box>
                    </Box>
                    <Stack paddingMd={1} paddingXs={1} fullWidth className="scrollable-container">
                        <AuditTrailList />
                    </Stack>
                </Stack>
            </AuditTrailWrap>
        </>
    )
}
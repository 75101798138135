import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  CircleLoader,
  Icon,
} from "@ntpkunity/controls";
import { RiskAssessmentGraph } from "../risk-assessment-graph/risk-assessment-graph";
import { AlertStatusText, BasicStack } from "@src/components/dashboard";
import { Stack } from "@src/components/stack";
import { useEffect, useState, useRef } from "react";
import { RiskAssessmentDialog } from "../risk-assessment-dialog/risk-assessment-dialog.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkflowDetails } from "@src/services/configureApplicationService";
import { RiskAssessmentStackWrap } from "./risk-assessment-stack.style";
import { updateRatios } from "@src/components/redux/actions/riskAssessmentActions";
import { fetchActivityWorkflowData } from "@src/components/api/riskAssessment/checkWorkFlow";

export const RiskAssessmentStack = ({ exposureData }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [fraudScores, setFraudScores] = useState([]) as any;
  const [ratios, setRatios] = useState({
    ltiRatio: null,
    ltvRatio: null,
    dbrRatio: null,
    creditUtilization: null,
  }) as any;

  const dispatch = useDispatch();

  const { applicantDetails, loading, error } = useSelector(
    (state: any) => state?.applicantDetails
  );

  const creditCheckScore = useSelector(
    (state: any) => state.workQueue.credit_check_score
  );
  const { running_workflow_instance_id } = useSelector(
    (state: any) => state.workQueue
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!running_workflow_instance_id) return;

      try {
        const activities = ["FraudCheck_Response", "LTI", "LTV", "DBR", "CreditUtilization_Response"];
        const data = await fetchActivityWorkflowData(running_workflow_instance_id, activities);

        setFraudScores(data["FraudCheck_Response"]?.fraud?.scores || []);

        const newRatios = {
          ltiRatio: data["LTI"] ? Number(data["LTI"]).toFixed(2) : "0.00",
          ltvRatio: data["LTV"] ? Number(data["LTV"]).toFixed(2) : "0.00",
          dbrRatio: data["DBR"] ? Number(data["DBR"]).toFixed(2) : "0.00",
          creditUtilization: data["CreditUtilization_Response"]
            ? Number(data["CreditUtilization_Response"]).toFixed(2)
            : "0.00",
        };

        setRatios(newRatios);
        dispatch(updateRatios(newRatios));
      } catch (err) {
        console.error("Error in fetching workflow data:", err);
      }
    };

    fetchData();
  }, [running_workflow_instance_id, dispatch]);

  const isFetching = useRef(false);

  useEffect(() => {
    const loadWorkflow = async () => {
      if (!running_workflow_instance_id || isFetching.current) return;

      isFetching.current = true;
      try {
        const data = await fetchWorkflowDetails(running_workflow_instance_id);
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
      }
      isFetching.current = false;
    };

    loadWorkflow();

    return () => {
      isFetching.current = false;
    };
  }, [running_workflow_instance_id]);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const annualEmploymentIncome =
    Number(
      applicantDetails?.customer_info?.employment_info?.annual_employment_income
    ) || 0;

  const annualOtherIncome =
    Number(
      applicantDetails?.customer_info?.employment_info?.annual_other_income
    ) || 0;

  const totalAnnualIncome = annualEmploymentIncome + annualOtherIncome;

  const monthlyIncome =
    totalAnnualIncome && !isNaN(totalAnnualIncome)
      ? (totalAnnualIncome / 12).toFixed(2)
      : "0.00";

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    }).format(value);
  };

  return (
    <>
      <Stack
        title={"Risk Assessment Matrices"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={<Icon name="ArrowLinkIcon" />}
            onClick={handleOpenDialog}
          />
        }
      >
        <RiskAssessmentStackWrap
          theme={theme}
          className="risk-assessment-stack-wrap"
        >
          <Box theme={theme} className="" mt={1}>
            {loading ? (
              <CircleLoader theme={theme} size="sm" />
            ) : error ? (
              <div>Error: {error}</div>
            ) : (
              <>
                <Box theme={theme} className="risk-assessment-alerts">
                      <RiskAssessmentGraph />
                      <AlertStatusText
                      text={"Fraud Check"}
                      statusText={'Pass'}
                      success
                      fullWidth
                      />
                  <BasicStack
                        text={"Monthly Income:"}
                        subTitle={`$ ${formatCurrency(monthlyIncome)}`}
                    fullWidth
                    textJustify
                  />
                  <BasicStack
                    text={"Monthly Debt:"}
                    subTitle={
                      `$ ${applicantDetails?.estimated_monthly_payment
                        ? formatCurrency(
                            applicantDetails.estimated_monthly_payment
                          )
                        : "--"}`
                    }
                    fullWidth
                    textJustify
                  />
                  <BasicStack
                    text={"Current Exposure:"}
                    subTitle={
                      `$ ${exposureData?.total_exposure
                        ? formatCurrency(exposureData.total_exposure)
                        : "--"}`
                    }
                    fullWidth
                    textJustify
                  />
                  <AlertStatusText
                    text={"Credit Utilisation:"}
                    statusText={ratios.creditUtilization !== null
                      ? `${ratios.creditUtilization}%`
                      : "--"}
                    success
                    fullWidth
                  />
                  <AlertStatusText
                    text={"LTI Ratio:"}
                    statusText={ratios.ltiRatio !== null && !isNaN(ratios.ltiRatio)
                      ? `${ratios.ltiRatio}`
                      : "--"}
                    notVerified
                    fullWidth
                  />
                  <AlertStatusText
                    text={"LTV Ratio:"}
                    statusText={ratios.ltvRatio !== null && !isNaN(ratios.ltvRatio)
                      ? `${ratios.ltvRatio}%`
                      : "--"}
                    notVerified
                    fullWidth
                  />
                  <AlertStatusText
                    text={"DBR Ratio:"}
                    statusText={ratios.dbrRatio !== null && !isNaN(ratios.dbrRatio)
                      ? `${ratios.dbrRatio}%`
                      : "--"}
                    notVerified
                    fullWidth
                  />
                </Box>
              </>
            )}
          </Box>
        </RiskAssessmentStackWrap>
      </Stack>

      <RiskAssessmentDialog
        open={isOpen}
        closeDialog={handleCloseDialog}
        applicantDetails={applicantDetails}
        exposureData={exposureData}
      />
    </>
  );
};

import { useTheme } from "@mui/material"
import { AuditTrailListWrap } from "./audit-trail-list.style"
import { Box, Accordion } from "@ntpkunity/controls"
import { Activities } from "../../activities/activities.component"
import { Stack } from "@src/components"
import { Typography } from "@ntpkunity/controls"

export const AuditTrailList = () => {
    const theme = useTheme();

    return(
        <>
            <AuditTrailListWrap theme={theme} className="audit-trail-list-wrap">
                <Box theme={theme} className="accordion-box weekdays">
                    <Accordion
                        theme={theme}
                        items={[
                        {
                            content: (
                            <>
                                <Activities />
                            </>
                            ),
                            title: <><Typography theme={theme} variant="body2" component={"span"} className="text-muted">Today</Typography></>,
                            key: "1",
                            isExpanded: true,
                            id: "1",
                            ariaControls: "1",
                        },
                        {
                            content: (
                            <>
                                <Activities />
                            </>
                            ),
                            title: <><Typography theme={theme} variant="body2" component={"span"} className="text-muted">Yesterday</Typography></>,
                            key: "1",
                            isExpanded: true,
                            id: "1",
                            ariaControls: "1",
                        },
                        {
                            content: (
                            <>
                                <Activities />
                            </>
                            ),
                            title: <><Typography theme={theme} variant="body2" component={"span"} className="text-muted">Last 7 Days</Typography></>,
                            key: "1",
                            isExpanded: true,
                            id: "1",
                            ariaControls: "1",
                        },
                        ]}
                        shouldCollapse
                    />
                </Box>
            </AuditTrailListWrap>
        </>
    )
}
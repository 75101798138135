import { useTheme } from "@mui/material"
import { RiskAssessmentGraphWrap } from "./risk-assessment-graph.style"
import { Image, Box, Typography, Label } from "@ntpkunity/controls"
import graph from "@src/assets/icons/assessment-graph.svg"
import { BasicList, Stack } from "@src/components"
import axios from "axios"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

export const RiskAssessmentGraph = () => {
    const theme = useTheme();


    const dispatch = useDispatch();

    const creditCheckScore = useSelector((state: any) => state.riskAssessment.credit_check_score);
    const running_workflow_instance_id = useSelector((state: any) => state.workQueue.running_workflow_instance_id);

    useEffect(() => {
        const fetchCreditScore = async () => {
            try {
                const base_url = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${base_url}/cde/get_activity_workflow_data`, {
                    params: {
                        workflowInstanceId: running_workflow_instance_id,
                        activity_name: "credit_score",
                    },
                });

                const score = response.data?.score;

                dispatch({
                    type: "SET_CREDIT_SCORE",
                    payload: score,
                });
            } catch (err) {
                console.error("Error fetching credit score:", err);
            }
        };

        if (running_workflow_instance_id) {
            fetchCreditScore();
        }
    }, [running_workflow_instance_id, dispatch]);

    return (
        <>
            <RiskAssessmentGraphWrap theme={theme} className="risk-assessment-graph-wrap">
                <Stack
                    paddingMd={0}
                    paddingXs={0}
                    title={"Basic Details"}
                    actionArea={
                        <Label
                            theme={theme}
                            text="Risk Tier 2"
                            state="primary"
                            textTransform="none"
                        />
                    }
                >
                    <Box theme={theme} className="graph">
                        <Image theme={theme} src={graph} alt={graph} />
                        <Box theme={theme} className="graph-text">
                            <Typography theme={theme} variant="body1" component={"span"} className="credit-score">Credit Score</Typography>
                            <Box theme={theme} display={'flex'}>
                                <Typography theme={theme} variant="h4" component={"h4"} className="text-primary">{creditCheckScore}</Typography>
                                <Typography theme={theme} variant="h4" component={"h4"} className="number">/<span>710</span></Typography>
                            </Box>
                        </Box>
                        <Box theme={theme} mt={2}>
                            <Label
                                theme={theme}
                                text="Excellent"
                                state="primary"
                                textTransform="none"
                            />
                        </Box>
                    </Box>
                    <BasicList options={[
                        {
                            title: "Credit Utilisation Ratio:",
                            subtitle: '30%',
                            textRight: true
                        },
                    ]}
                    />
                </Stack>
            </RiskAssessmentGraphWrap>
        </>
    )
}
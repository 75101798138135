import { useTheme } from "@mui/material";
import { CreditScoreCounterWrap } from "./credit-score-counter.style";
import { Typography, Box, Label } from "@ntpkunity/controls";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";

export const CreditScoreCounter = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const creditCheckScore = useSelector((state: any) => state.riskAssessment.credit_check_score);
    const running_workflow_instance_id = useSelector((state: any) => state.workQueue.running_workflow_instance_id);

    useEffect(() => {
        const fetchCreditScore = async () => {
            try {
                const base_url = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${base_url}/cde/get_activity_workflow_data`, {
                    params: {
                        workflowInstanceId: running_workflow_instance_id,
                        activity_name: "credit_score",
                    },


                });

                const score = response.data?.score;

                dispatch({
                    type: "SET_CREDIT_SCORE",
                    payload: score,
                });
            } catch (err) {
                console.error("Error fetching credit score:", err);
            }
        };

        if (running_workflow_instance_id) {
            fetchCreditScore();
        }
    }, [running_workflow_instance_id, dispatch]);

    return (
        <>
            <CreditScoreCounterWrap theme={theme} className="credit-score-counter-wrap">
                <Typography
                    theme={theme}
                    variant="body1"
                    component={"span"}
                >
                    Credit Score
                </Typography>
                <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                    <Typography
                        theme={theme}
                        variant="h4"
                        component={"h4"}
                        className="fw-bold text-primary"
                    >
                        {creditCheckScore ? creditCheckScore : "--"}
                        <span className="number">/900</span>
                    </Typography>
                    <Label
                        theme={theme}
                        text="Excellent"
                        state="primary"
                        textTransform="none"
                    />
                </Box>
            </CreditScoreCounterWrap>
        </>
    );
};
import { useTheme } from "@mui/material";
import { Button, CircleLoader, Grid, Icon } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import { useEffect, useState } from "react";
import { ApplicantDetailDialog } from "../applicant-detail-dialog/applicant-detail-dialog.component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchApplicantDetailsRequest } from "@src/components/redux/actions/applicantDetailsActions";
import moment from "moment";
import { retrieveWorkqueueRequest } from "@src/components/redux/actions/workQueueActions";

export const ApplicantDetailsStack = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const { applicantDetails, loading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  const { workQueueDetail } = useSelector((state: any) => state.workQueue);


  const { reference_number } = useParams();

  useEffect(() => {
    if (!reference_number) return;
    dispatch(retrieveWorkqueueRequest(reference_number));
  }, [dispatch, reference_number, location.pathname]);
  
  useEffect(() => {
    if (!reference_number) return;
    const apiKey = workQueueDetail?.data?.x_api_key;
  
    if (apiKey) {
      dispatch(fetchApplicantDetailsRequest(reference_number, apiKey));
    }
  }, [dispatch, reference_number, workQueueDetail, location.pathname]);
  

  const handleOpenDialog = (reference_number) => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const getYearsMonths = (date) => {
    if (!date) return "--";

    const startDate = moment(date);
    const now = moment();
    const years = now.diff(startDate, "years");
    const months = now.diff(startDate, "months") % 12;

    const formattedYears = years.toString().padStart(2, "0");
    const formattedMonths = months.toString().padStart(2, "0");

    return `${formattedYears} Yrs, ${formattedMonths} Months`;
  };

  const fields = [
    {
      title: "Applicant Name:",
      key: () =>
        `${applicantDetails?.customer_info?.first_name || ""} ${
          applicantDetails?.customer_info?.last_name || ""
        }`,
    },
    {
      title: "Address:",
      key: () =>
        applicantDetails?.customer_info?.customer_addresses?.[0]
          ?.address_line_1 || "--",
    },
    {
      title: "Residence Time:",
      key: () =>
        getYearsMonths(
          applicantDetails?.customer_info?.customer_addresses?.[0]?.move_in_date
        ),
    },
    {
      title: "Address Type:",
      key: () =>
        applicantDetails?.customer_info?.customer_addresses?.[0]
          ?.address_type || "--",
    },
    {
      title: "Date of Birth:",
      key: () =>
        applicantDetails?.customer_info?.customer_license[0].date_of_birth.split(
          "T"
        )[0] || "--",
    },
    {
      title: "NIC Number:",
      key: () => applicantDetails?.customer_info?.ssn || "--",
    },
    {
      title: "Employer Name:",
      key: () => applicantDetails?.customer_info?.employment_info?.name || "--",
    },
    {
      title: "Employment Status:",
      key: () =>
        applicantDetails?.customer_info?.employment_info?.employment_type ||
        "--",
    },
    {
      title: "Employment Time:",
      key: () =>
        getYearsMonths(
          applicantDetails?.customer_info?.employment_info?.employed_since
        ) || "--",
    },
  ];

  return (
    <>
      <Stack
        title={"Applicant Details"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={
              <>
                <Icon name="ArrowLinkIcon" />
              </>
            }
            onClick={() => handleOpenDialog(reference_number)}
          />
        }
      >
        {loading ? (
          <CircleLoader theme={theme} size={"sm"} />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <BasicList
            options={fields.map((field) => ({
              title: field.title,
              subtitle: typeof field.key === "function" ? field.key() : "--",
            }))}
          />
        )}
      </Stack>
      <ApplicantDetailDialog open={isOpen} closeDialog={handleCloseDialog} />
    </>
  );
};

import { combineReducers } from "redux";
import workQueueReducer from "./workQueueReducer";
import applicantDetailsReducer from "./applicantDetailsReducer";
import assetDetailsReducer from "./assetDetailsReducer";
import assetDialogReducer from "./assetDialogueReducer";
import riskAssessmentReducer from "./riskAssessmentReducer";
import financeStructureReducer from "../reducers/financeStructureReducer";

export interface RootState {
  workQueue: ReturnType<typeof workQueueReducer>;
  applicantDetails: ReturnType<typeof applicantDetailsReducer>;
  assetDetails: ReturnType<typeof assetDetailsReducer>;
  assetDialog: ReturnType<typeof assetDialogReducer>;
  riskAssessment: ReturnType<typeof riskAssessmentReducer>;
  financeStructure: ReturnType<typeof financeStructureReducer>;
}

const rootReducer = combineReducers({
  workQueue: workQueueReducer,
  applicantDetails: applicantDetailsReducer,
  assetDetails: assetDetailsReducer,
  assetDialog: assetDialogReducer,
  riskAssessment: riskAssessmentReducer,
  financeStructure: financeStructureReducer,
});

export default rootReducer;

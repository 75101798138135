import { useTheme } from "@mui/material";
import { Box, CircleLoader, Icon, Input, Select, Typography } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { fetchApplicantDetailsRequest } from "@src/components/redux/actions/applicantDetailsActions";
import { Stack } from "@src/components/stack";
import { FinanceStructureWrap } from "./finance-structure.style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FinanceStructureModifyDialog } from "../finance-structure-modify-popup/finance-structure-modify-popup-dialog.component";
import { ArrowDown } from "@src/components";
import { setSelectedStructure } from "@src/components/redux/actions/financeStructureActions";

export const FinanceStructure = () => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);
  const [selectedStructureKey, setSelectedStructureKey] = useState<string | null>(null);
  const [financeStructures, setFinanceStructures] = useState<any[]>([]);
  const [currentStructure, setCurrentStructure] = useState<any>(null);

  const { applicantDetails, loading, error } = useSelector((state: any) => state?.applicantDetails);
  const { selectedStructure } = useSelector((state: any) => state?.financeStructure); 
  const apiKey = useSelector((state: any) => state.workQueue.apiKey);
  const { reference_number } = useParams();
  const dispatch = useDispatch();





  useEffect(() => {
    if (reference_number) {
      dispatch(fetchApplicantDetailsRequest(reference_number, apiKey));
    }
  }, [dispatch, reference_number]);

  const normalizeStructureData = (data: any) => ({
    finance_type: data?.finance_type || "--",
    financed_amount: data?.net_finance_amount || 0,
    periodic_payment: data?.estimated_monthly_payment || 0,
    terms: data?.order_submissions?.[0]?.decision?.terms || 0,
    frequency: data?.rental_frequency || "--",
    asset_cost: data?.selling_price || 0,
    down_payment_value: data?.down_payment_value || 0,
    tax_amount: data?.tax_amount || 0,
    net_trade_in: data?.order_tradein?.trade_in_amount || 0,
    charges: data?.charges || 0,
    rebate: data?.rebate || 0,
    discount: data?.discount || 0,
    add_ons: data?.order_options?.reduce((acc: number, option: any) => acc + (option.applied_price || 0), 0) || 0,
    insurance: data?.order_fnI?.reduce((acc: number, item: any) => acc + (item.applied_price || 0), 0) || 0,
    interest_rate: data?.apr || 0,
    dealer_fees: data?.order_fees?.reduce((acc: number, fee: any) => acc + (fee.applied_price || 0), 0) || 0,
  });

  const persistedStructures = useSelector((state: any) => state?.financeStructure?.financeStructures) || [];

  useEffect(() => {
    if (applicantDetails) {
      const baseData = normalizeStructureData(applicantDetails);

      const defaultStructure = {
        key: "requested",
        label: "Requested",
        data: baseData,
      };

      const aiRecommendation = {
        key: "ai_recommendation",
        label: "AI Recommendation",
        data: baseData,
      };

      const initialStructures = persistedStructures.length ? persistedStructures : [defaultStructure, aiRecommendation];

      setFinanceStructures(initialStructures);

      const initialStructure = persistedStructures?.find((s) => s.key === selectedStructureKey) || selectedStructure || defaultStructure;
      setCurrentStructure(initialStructure);
      setSelectedStructureKey(initialStructure.key);
      dispatch(setSelectedStructure(initialStructure));
    }
  }, [applicantDetails, persistedStructures, selectedStructure, dispatch]);


  const handleSaveStructure = (structure: any) => {
    const normalizedData = structure.key.startsWith("Underwriter")
      ? normalizeStructureData(structure.data)
      : structure.data;

    const normalizedStructure = { ...structure, data: normalizedData };
    setCurrentStructure(normalizedStructure);
    setSelectedStructureKey(normalizedStructure.key);
    dispatch(setSelectedStructure(normalizedStructure));
    dispatch({
      type: "SET_SELECTED_STRUCTURE_KEY",
      payload: normalizedStructure.key,
    });
  };

  const handleAddNewStructure = () => {
    const underwriterCount = financeStructures.filter((s) => s.key.startsWith("Underwriter")).length;
    const newStructureKey = `Underwriter${underwriterCount + 1}`;

    const newStructure = {
      key: newStructureKey,
      label: `Underwriter ${underwriterCount + 1}`,
      data: normalizeStructureData(applicantDetails),
    };

    const updatedStructures = [...financeStructures, newStructure];
    setFinanceStructures(updatedStructures);

    dispatch({
      type: "UPDATE_FINANCE_STRUCTURES",
      payload: updatedStructures,
    });

  };

  const handleOpenDialog = () => setOpened(true);
  const handleCloseDialog = () => setOpened(false);

  const formatNumber = (value: any): string | number => {
    if (value === null || value === undefined) return "--";
    if (typeof value === "number" && !isNaN(value)) {
      return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    }
    return typeof value === "string" ? value : "--";
  };

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => {
      if (Array.isArray(acc) && part.includes('[')) {
        const [arrKey, index] = part.split(/\[|\]/).filter(Boolean);
        return acc?.[arrKey]?.[Number(index)];
      }
      return acc?.[part];
    }, obj);
  };

  const fields = [
    { label: "Finance Type", key: "finance_type" },
    { label: "Financed Amount", key: "financed_amount", startAdornment: "$" },
    { label: "Periodic Payment", key: "periodic_payment", startAdornment: "$" },
    { label: "Terms", key: "terms" },
    { label: "Frequency", key: "frequency" },
    { label: "Asset Cost", key: "asset_cost", startAdornment: "$" },
    { label: "Down Payment", key: "down_payment_value", startAdornment: "$" },
    { label: "Tax on Asset Cost", key: "tax_amount", startAdornment: "$" },
    { label: "Net Trade-in", key: "net_trade_in", startAdornment: "$" },
    { label: "Charges", key: "charges", startAdornment: "$" },
    { label: "Rebate", key: "rebate", startAdornment: "$" },
    { label: "Discount", key: "discount", startAdornment: "$" },
    { label: "Add-ons", key: "add_ons", startAdornment: "$" },
    { label: "Insurance", key: "insurance", startAdornment: "$" },
    { label: "Interest Rate", key: "interest_rate", startAdornment: "%" },
    { label: "Dealer Fees", key: "dealer_fees", startAdornment: "$" },
  ];

  return (
    <>
      <FinanceStructureWrap theme={theme} className="finance-structure-wrap">
        <Stack
          title={"Finance Structure"}
          paddingXs={2}
          paddingMd={2}
          actionArea={
            <a href="javascript:void(0)" className="text-primary primary-alert dFlex alignItemsCenter" onClick={handleOpenDialog}>
              Add/Modify <Icon name="ArrowLinkIcon" />
            </a>
          }
        >
          {loading ? (
            <CircleLoader theme={theme} size="sm" />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <Box theme={theme} className="checkbox-section">
                    <Box theme={theme} className="custom-dropdown">
                      <Typography theme={theme} className="text-muted" variant="body2" component={"span"} display={"block"}>
                        Structure Name:
                      </Typography>
                      <Typography theme={theme} variant="body2" component={"span"} className="fw-medium">
                        {financeStructures.find(s => s.key === selectedStructureKey)?.label || "--"}
                      </Typography>
                    </Box>
                  </Box>

              <Box theme={theme} className="child-scrollable-container">
                <BasicList
                  input
                  options={fields.map((field) => {
                    const value = field.key.includes("[")
                      ? getNestedValue(normalizeStructureData(applicantDetails), field.key)
                      : normalizeStructureData(applicantDetails)?.[field.key];

                    return {
                      title: field.label,
                      textRight: true,
                      action: (
                        <Input
                          theme={theme}
                          type="text"
                          startAdornment={field.startAdornment || ""}
                          value={formatNumber(value)}
                          inputClass="text-right"
                          readOnly
                        />
                      ),
                    };
                  })}
                />
              </Box>
            </>
          )}
        </Stack>
        <ArrowDown />
      </FinanceStructureWrap>

      <FinanceStructureModifyDialog
        open={open}
        closeDialog={handleCloseDialog}
        onSaveStructure={handleSaveStructure}
        selectedStructureKey={selectedStructureKey}
        financeStructures={financeStructures}
        handleAddNewStructure={handleAddNewStructure}
      />
    </>
  );
};
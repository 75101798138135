import { useTheme } from "@mui/material";
import { Box, Grid, Typography, Label } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import { CreditScoreCounter } from "../credit-score-counter/credit-score-counter.component"
import { RiskAssessmentDialogDataWrap } from "./risk-assessment-dialog-data.style"

export const RiskAssessmentDialogData = ({ riskAssessmentDialogueData }) => {
  const theme = useTheme();

  const {
    creditUtilisationRatio = "N/A",
    dtiRatio = "N/A",
    ltvRatio = "N/A",
    ltiRatio = "N/A",
    totalMonthlyInstallment = "N/A",
    totalMonthlyIncome = "N/A",
    creditCardDebt = "N/A",
    applications = "N/A",
    contracts = "N/A",
    activeExposure = "N/A",
    pipelineExposure = "N/A",
    activePipelineExposure = "N/A",
    periodicPayment = "N/A",
  } = riskAssessmentDialogueData ?? {};

  return (
    <>
      <RiskAssessmentDialogDataWrap theme={theme} className="risk-assesment-dialog-data-wrap">
        <Grid
          theme={theme}
          container
          rowSpacing={0}
          columnSpacing={2}
          sx={{ mt: 0 }}
        >
          <Grid item xs={6} theme={theme}>
            <Stack paddingMd={0} paddingXs={0}>
              <Box theme={theme} display={'flex'} alignItems={'center'} justifyContent={'space-between'} p={1}>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component={"span"}
                  className="fw-sbold text-body2"
                  display={'block'}
                >
                  Basic Details
                </Typography>
                <Label 
                  theme={theme}
                  text={`Risk Tier 2`}
                  state="primary"
                  textTransform="none" 
                />
              </Box>
              <BasicList
                noWrap
                options={[
                  { title: "Credit Utilisation Ratio:", subtitle: `${creditUtilisationRatio}`, textRight: true },
                  { title: "DTI Ratio:", subtitle: `${dtiRatio}`, textRight: true },
                  { title: "LTV Ratio:", subtitle: `${ltvRatio}`, textRight: true },
                  { title: "LTI Ratio:", subtitle: `${ltiRatio}`, textRight: true },
                ]}
              />
              <Box theme={theme} pt={2}>
                <CreditScoreCounter />
              </Box>
              <Typography
                theme={theme}
                variant="subtitle2"
                component={"span"}
                className="fw-sbold text-body2"
                display={'block'}
                p={1}
                mt={2}
              >
                Monthly Expenses
              </Typography>
              <BasicList
                noWrap
                options={[
                  { title: "Total Monthly Installment:", subtitle: `$${totalMonthlyInstallment}`, textRight: true },
                  { title: "Total Monthly Income After Tax:", subtitle: `$${totalMonthlyIncome}`, textRight: true },
                  { title: "Debt of Credit Card:", subtitle: `$${creditCardDebt}`, textRight: true },
                ]}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} theme={theme}>
            <Stack paddingMd={0} paddingXs={0}>
              <Typography
                theme={theme}
                variant="subtitle2"
                component={"span"}
                className="fw-sbold text-body2"
                display={'block'}
                p={1}
              >
                Total Exposure
              </Typography>
              <BasicList
                noWrap
                options={[
                  { title: "Applications:", subtitle: `${applications}`, textRight: true },
                  { title: "Contracts:", subtitle: `${contracts}`, textRight: true },
                  { title: "Active Exposure:", subtitle: `${activeExposure}`, textRight: true },
                  { title: "Pipeline Exposure:", subtitle: `${pipelineExposure}`, textRight: true },
                  { title: "Active + Pipeline Exposure:", subtitle: `${activePipelineExposure}`, textRight: true },
                  { title: "Periodic Payment:", subtitle: `$${periodicPayment}`, textRight: true },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>
      </RiskAssessmentDialogDataWrap>
    </>
  );
};

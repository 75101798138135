import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu } from "@ntpkunity/controls";
import WorkqueueStatus from "./workqueue-status/workqueue-status.component";
import { OrderStatus } from "@src/helpers/enum";
import { useNavigate } from "react-router-dom";

const WorkqueueTableRow = ({ row }) => {
  // console.log(row.reference_number)
  const theme = useTheme();

  const navigate = useNavigate();

  const handleOptionClick = (key) => {
    console.log("Option clicked:", key);

    if (key === "view") {
      if (row?.reference_number) {
        navigate(`/cde/dashboard/${row.reference_number}`);
      } else {
        console.error("Row ID is missing for navigation.");
      }
    } else if (key === "assigntome") {
      console.log("Assign To Me clicked for row:", row);
    }
  };

  return ( 
    <TableRow className="child-tr">
      <TableCell className="text-body1">{row.status ?? "N/A"}</TableCell>
      <TableCell className="text-body1">
        <a href="javascript:void(0)" className="text-primary">
          {row.reference_number ?? "N/A"}
        </a>
      </TableCell>
      <TableCell className="text-body1">{row.introducer_name ?? "N/A"}</TableCell>
      <TableCell className="text-body1 text-right">
        ${row.finance_amount?.toLocaleString() ?? "0.00"}
      </TableCell>
      <TableCell className="text-body1">{row.customer_name ?? "N/A"}</TableCell>
      <TableCell className="text-body1">{row.region ?? "N/A"}</TableCell>
      <TableCell className="text-body1">{row.state ?? "N/A"}</TableCell>
      <TableCell className="text-body1">{row.business ?? "N/A"}</TableCell>
      <TableCell className="action-cell fixed-cell">

        <Menu
          theme={theme}
          options={[
            { optionText: "View", optionkey: "view" },
            { optionText: "Assign To Me", optionkey: "assigntome" },
            { optionText: "Assign to team member", optionkey: "Assign to team member" },
          ]}
          handleOptionClick={(event, key) => handleOptionClick(key)} 
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default WorkqueueTableRow;

import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FinanceStructureWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.finance-structure-wrap": {
    ".stack-wrap": {
        ".basic-list-wrap": {
            paddingTop: 0,
            ".list": {
                ".title": {
                    minWidth: 150,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'inline',
                    "@media only screen and (min-width: 1599.95px)": {
                        minWidth: 207,
                        maxWidth: '60%'
                    }
                },
                ".action-area": {
                    "@media only screen and (min-width: 1599.95px)": {
                        maxWidth: '40%'
                    }
                }
            }
        },
        ".checkbox-section": {
            paddingBottom: 8,
            paddingTop: 16,
            display: 'flex',
            gap: 16,
            ".empty": {
                minWidth: 150,
                "@media only screen and (min-width: 1599.95px)": {
                    minWidth: 207,
                },
                "@media only screen and (min-width: 2200.95px)": {
                    minWidth: '60%',
                }
            },
            ".custom-checkbox-wrap": {
                overflow: 'hidden',
                ".u-custom-control-label": {
                    width: '100%',
                    margin: 0,
                    ".MuiTypography-root": {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }
                }
            }   
        }
    }
    
  },
}));

import { useTheme } from "@mui/material"
import { StickyNotesWrap } from "./sticky-notes.style"
import { Typography, Box, Button, Icon } from "@ntpkunity/controls";
import { clsx } from "clsx"
import { useDispatch, useSelector } from "react-redux";
import { fetchMyNotesRequest, updateNoteRequest } from "@src/components/redux/actions/myNotesActions";
import { useParams } from "react-router-dom";

export const StickyNotes = ({ title, subTitle, color, identifier }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { reference_number } = useParams();

  const { applicantDetails } = useSelector(
    (state: any) => state.applicantDetails
  );

  const handleDeleteStickyNote = () => {
    const updatedComment = `${subTitle.parsedComment} #title=${
      title || "--"
      } #priority=${subTitle.priority || "--"} #color=${subTitle.color || "--"
      } #isSticky=false`.trim();
    dispatch(
      updateNoteRequest({
        identifier: identifier,
        comment: updatedComment,
        // reference_id: applicantDetails?.customer_info?.reference_id
      })
    );

    dispatch(fetchMyNotesRequest(reference_number));

  };

  return (
    <>
      <StickyNotesWrap
        theme={theme}
        className={clsx("sticky-notes-wrap", {
          "light-yellow": color === "light-yellow",
          "light-ferozi": color === "light-ferozi",
          "light-purple": color === "light-purple",
        })}
      >
        <Box theme={theme} className="notes-head">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            className="fw-sbold title"
          >
            {title}
          </Typography>
        </Box>
        <Box theme={theme} className="notes-body">
          <Typography
            theme={theme}
            variant="body1"
            component={"span"}
            className="text-muted text-body1 subTitle"
          >
            {subTitle}
          </Typography>
        </Box>
        <Box theme={theme} className="notes-footer">
          {/* <Button
                        theme={theme}
                        defaultBtn
                        iconText={
                        <>
                            <Icon name="ExpandLeftIcon" />
                        </>
                        }
                        onClick={()=>{}}
                    /> */}
          <Button
            theme={theme}
            defaultBtn
            iconText={
              <>
                <Icon name="IconDeleteV2" />
              </>
            }
            onClick={handleDeleteStickyNote}
          />
        </Box>
      </StickyNotesWrap>
    </>
  );
};
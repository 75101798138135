import { useTheme } from "@mui/material";
import { Accordion, Box, Button, Dialog } from "@ntpkunity/controls";
import { AssetDetailsList } from "../asset-detail-list/asset-detail-list.component";
import { Popup } from "@src/components/Dialog/dialog.component";
import { AssetDetailDialogWrap } from "./asset-detail-dialog.style"
import { AssetDetailCarousel } from "../asset-detail-carousel/asset-detail-carousel.component"
import { useSelector } from "react-redux";
import { Alert } from "@src/components"

export const AssetDetailDialog = ({ open, closeDialog }) => {
  const theme = useTheme();

  return (
    <>
      <Popup>
        <Dialog
          theme={theme}
          size={"md"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Asset Details"}
          disablePortal
          noFooter
        >
          <AssetDetailDialogWrap
            theme={theme}
            className="asset-detail-dialog-wrap collapse-wrap"
          >
            <Box theme={theme} className="accordion-box bg-light">
              <Accordion
                theme={theme}
                items={[
                  {
                    content: (
                      <>
                        <AssetDetailCarousel />
                        <Box theme={theme} mt={1} mb={2}>
                          <Alert text={"Verified"} success fullWidth/>
                        </Box>
                        <AssetDetailsList />
                      </>
                    ),
                    title: "Asset",
                    key: "asset",
                    isExpanded: true,
                    id: "asset",
                    ariaControls: "asset",
                  },
                  // {
                  //   content: (
                  //     <>
                        
                  //     </>
                  //   ),
                  //   title: "Asset 2",
                  //   key: "asset-2",
                  //   isExpanded: false,
                  //   id: "asset-2",
                  //   ariaControls: "asset-2",
                  // },
                  // {
                  //   content: (
                  //     <>
                        
                  //     </>
                  //   ),
                  //   title: "Asset 3",
                  //   key: "asset-3",
                  //   isExpanded: false,
                  //   id: "asset-3",
                  //   ariaControls: "asset-3",
                  // },
                ]}
                shouldCollapse
              />
            </Box>
          </AssetDetailDialogWrap>
        </Dialog>
      </Popup>
    </>
  );
};

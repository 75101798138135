import { useTheme } from "@mui/material"
import { CreditScoreCounterWrap } from "./credit-score-counter.style"
import { Typography, Box, Label } from "@ntpkunity/controls"



export const CreditScoreCounter = () => {
    const theme = useTheme()

    return(
        <>
            <CreditScoreCounterWrap theme={theme} className="credit-scrore-counter-wrap">
                <Typography
                    theme={theme}
                    variant="body2"
                    component={"span"}
                    className="text-body2"
                >
                    Credit Score
                </Typography>
                <Box theme={theme} display={'flex'} alignItems={'center'} gap={1}>
                    <Typography
                        theme={theme}
                        variant="h4"
                        component={"h4"}
                        className="fw-sbold text-body2 text-primary"
                    >
                        754 <span className="number">/ 900</span>
                    </Typography>
                    <Label 
                        theme={theme} 
                        text="Excellent"
                        state="success"
                        textTransform="none" 
                    />
                </Box>
            </CreditScoreCounterWrap>
        </>
    )
}
import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu, Box } from "@ntpkunity/controls";
import WorkqueueStatus from "./workqueue-status/workqueue-status.component";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  markRowAsRead,
  updateApiKey,
  updateAssignedRowId,
  updateCreditCheckScore,
  updateRunningWorkflowId,
} from "@src/components/redux/actions/workQueueActions";
import moment from "moment";
import unlock from "@assets/icons/unlock-icon.svg"
import { useState } from "react";

const formattedDate = (date) =>
  date ? moment(date).format("MM/DD/YYYY hh:mm A") : "--";

const WorkqueueTableRow = ({ row, refetchData, selectedTab, onAssignToTeamMember }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const readRowsArray = useSelector((state: any) => state?.workQueue?.readRows || []);

  const readRows = new Set(readRowsArray);
  const isRead = readRows.has(row.reference_number);

  const navigateToDashboard = () => {
    if (row?.reference_number) {
      dispatch(markRowAsRead(row.reference_number));
      if (selectedTab === "Credit Requests") {
        row?.application_type === "corporate" ?
          navigate(`/cde/dashboard/corporate/${row.reference_number}`)
          :
          navigate(`/cde/dashboard/${row.reference_number}`)
      } else {
        navigate(`/cde/dashboard/funding_request/${row.reference_number}`);
      }
      dispatch(updateRunningWorkflowId(row.running_workflow_instance_id));
      dispatch(updateAssignedRowId(row.id));
      dispatch(updateCreditCheckScore(row.credit_check_score));
      dispatch(updateApiKey(row.x_api_key)); 
    } else {
      console.error("Row ID is missing for navigation.");
    }
  };

  const handleOptionClick = async (key) => {
    if (key === "view") {
      navigateToDashboard();
    } else if (key === "assigntome") {
      try {
        const assignedUsername = row.assigned_to_username || "Asad";

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/cde/application_queue_by_id/${row.id}`,
          { assigned_to_username: assignedUsername },
          { headers: { "Content-Type": "application/json" } }
        );

        if (response.status === 200) {
          refetchData();
        } else {
          console.error("Assignment failed:", response);
        }
      } catch (error) {
        console.error(
          "Error assigning row to user:",
          error.response?.data || error
        );
      }
    } else if (key === "assigntoteammember") {
      onAssignToTeamMember(row);
    }
    
  };

  return (
    <TableRow className="child-tr">
      <TableCell className={`action-cell fixed-cell ${isRead ? "" : "unread-email"}`}>
        <img src={unlock} alt="unlock icon" />
      </TableCell>
      <TableCell className="text-body1">
        <WorkqueueStatus status={row.status} />
      </TableCell>
      <TableCell className="text-body1">
        <a
          href="javascript:void(0)"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary"
          onClick={(e) => {
            e.preventDefault();
            navigateToDashboard();
          }}
        >
          {row.reference_number ?? "--"}
        </a>
      </TableCell>
      <TableCell className="text-body1">
        {row.dealership_name ?? "--"}
      </TableCell>
      <TableCell className="text-body1 text-right">
        <Box theme={theme} display={'flex'} justifyContent={'space-between'}>
          <Box theme={theme}>$</Box>
          {row.amount_financed?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) ?? "0.00"}
        </Box>
      </TableCell>
      <TableCell className="text-body1">
        {row.reference_number === "HN-JS-2025-38"
          ? "HiTech Ltd"
          : row.applicant_name ?? "--"}
      </TableCell>
      <TableCell className="text-body1">{row.state ?? "--"}</TableCell>
      <TableCell className="text-body1">
        {row.reference_number === "HN-JS-2025-38"
          ? "Company"
          : row.business ?? "Individual"}
      </TableCell>
      <TableCell className="text-body1">
        {row.assigned_to_username ?? "--"}
      </TableCell>
      <TableCell className="text-body1">
        {formattedDate(row.created_at ?? "--")}
      </TableCell>
      <TableCell className="text-body1">
        {formattedDate(row.updated_at ?? "--")}
      </TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={[
            { optionText: "View", optionkey: "view" },
            { optionText: "Assign To Me", optionkey: "assigntome" },
            {
              optionText: "Assign to team member",
              optionkey: "assigntoteammember",
            },
          ]}
          handleOptionClick={(event, key) => handleOptionClick(key)}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default WorkqueueTableRow;
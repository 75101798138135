import { useTheme } from "@mui/material";
import { Accordion, Box, Button, Chip, Dialog, Label, Typography } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Popup } from "@src/components/Dialog/dialog.component";
import { StickyNotesDialogWrap } from './sticky-notes-popup.style'
import { Stack } from "@src/components/stack";
import circleTick from "@assets/icons/circle-tick.svg"

export const StickyNotesPopup = ({ open, closeDialog }) => {
  const theme = useTheme();

  return (
    <>
      <Popup>
        <Dialog
          theme={theme}
          size={"md"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Sticky Note"}
          disablePortal
          noFooter
        >
          <StickyNotesDialogWrap theme={theme} className="sticky-notes-dialog-wrap">
            <Stack paddingMd={1.5} paddingXs={1.5} title={'Reference Check'} bgLight 
                actionArea={<>
                    <Label theme={theme} text={'Internal'} state={'primary'}/>
                </>}>
            </Stack>
            <Box theme={theme} p={2}>
                    <Typography theme={theme} variant="body1" component={'span'} className="text-muted">
                        The reference checks with previous employers have been completed, providing additional insight into the applicant's 
                        work history and performance. This step is crucial to confirming the applicant's credibility and job stability. 
                        The final review process is now underway to assess all gathered information before making a decision on the credit application.
                    </Typography>
                </Box>
                <Box theme={theme} className="select-priority" p={2}>
                    <Typography theme={theme} variant="body2" component={'span'} className="select fw-sbold">Select Priority:</Typography>
                    <Box theme={theme} className="priority">
                        <Box theme={theme} className="light-grey">
                          <Chip 
                            theme={theme}
                            label={'#Low_Priority'} 
                            component={'span'}
                            href="javascript:void(0)"
                            clickable
                            variant="filled"
                          />
                          <img src={circleTick} />
                        </Box>
                        <Box theme={theme} className="light-grey">
                          <Chip 
                            theme={theme}
                            label={'#Low_Priority'} 
                            component={'span'}
                            href="javascript:void(0)"
                            clickable
                            variant="filled"
                          />
                          <img src={circleTick} />
                        </Box>
                        <Box theme={theme} className="light-grey selected">
                          <Chip 
                            theme={theme}
                            label={'#Low_Priority'} 
                            component={'span'}
                            href="javascript:void(0)"
                            clickable
                            variant="filled"
                          />
                          <img src={circleTick} />
                        </Box>
                    </Box>
                </Box>
                <Box theme={theme} className="select-priority" p={2}>
                    <Typography theme={theme} variant="body2" component={'span'} className="select fw-sbold">Select Color:</Typography>
                    <Box theme={theme} className="priority">
                        <Box theme={theme} className="clr-picker light-yellow">
                          <img src={circleTick} />
                        </Box>
                        <Box theme={theme} className="clr-picker light-purple">
                          <img src={circleTick} />
                        </Box>
                        <Box theme={theme} className="clr-picker light-ferozi selected">
                          <img src={circleTick} />
                        </Box>
                    </Box>
                </Box>
                <Box theme={theme} p={2} textAlign={'center'}>
                  <Button theme={theme} primary text={'Save Sticky Note'} />
                </Box>
          </StickyNotesDialogWrap>
        </Dialog>
      </Popup>
    </>
  );
};

import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  CircleLoader,
  DataTable,
  Icon,
  Pagination,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import WorkqueueTableRow from "./workqueue-table-row/workqueue-table-row.component";
import { WorkqueueFilter } from "./workqueue-filter/workqueue-filter.component";
import { Stack } from "@src/components/stack";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkqueueRequest, updateSortOrder, updatePagination } from "@src/components/redux/actions/workQueueActions";
import { useEffect } from "react";

export const WorkqueueTable = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  // const { workQueues, loading, error, filters, pagination, sortOrder } = useSelector((state: any) => state.workqueue);
  const { workQueues, loading, error, pagination } = useSelector((state: any) => state.workQueue);

  console.log("workQueues", workQueues);
  // useEffect(() => {
  //   dispatch(fetchWorkqueueRequest());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(fetchWorkqueueRequest(pagination.pageNumber, pagination.pageSize));
  }, [dispatch, pagination.pageNumber, pagination.pageSize]);

  const handlePageChange = (pageNumber) => {
    dispatch(updatePagination(pageNumber, pagination.pageSize));
  };

  // const handleSort = (column) => {
  //   const order = sortOrder.column === column && sortOrder.order === "asc" ? "desc" : "asc";
  //   dispatch(updateSortOrder(column, order));
  // };

  // const handlePaginationChange = (page, rowsPerPage) => {
  //   dispatch(updatePagination(page, rowsPerPage));
  // };

  const columns = [
    { key: "status", label: "Status" },
    { key: "applicationNumber", label: "Application Number" },
    { key: "dealershipName", label: "Dealership Name" },
    { key: "finance_amount", label: "Amount Financed" },
    { key: "applicantName", label: "Applicant Name" },
    { key: "region", label: "Region" },
    { key: "state", label: "State" },
    { key: "business", label: "Business" },
  ];

  return (
    <>
      <DataTableWrap theme={theme} className="data-table-wrap">
        <DataTable
          theme={theme}
          hasPagination={<Pagination theme={theme} count={100} />}
          // page={pagination.pageNumber + 1}
          // onChange={(event: React.ChangeEvent<any>, page: number) => {
          //   handlePageChange(page - 1); 
          // }}
          theadChildren={
            <>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.key} className="text-body1 fw-medium">
                    <Box theme={theme} className="table-order">
                      {column.label}
                      <Button
                        theme={theme}
                        defaultBtn
                        iconText={<Icon name="AscDescIcon" />}
                        onClick={() => { }}
                      />
                    </Box>
                  </TableCell>
                ))}
                <TableCell className="action-cell fixed-cell" />
              </TableRow>

              <TableRow className="filters-row">
                {columns.map((column) => (
                  <TableCell key={column.key}>
                    <WorkqueueFilter columnKey={column.key} />

                  </TableCell>
                ))}
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            loading ? (
              <TableRow>
                <CircleLoader theme={theme} size={"sm"} />
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={9}>Error: {error}</TableCell>
              </TableRow>
            ) : workQueues.length > 0 ? (
              workQueues.map((row, index) => (
                <WorkqueueTableRow key={index} row={row} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>No records found.</TableCell>
              </TableRow>
            )
          }
        />
      </DataTableWrap>
    </>
  );
};
import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const MyNotesListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.my-notes-list-wrap": {
      ".stack-user-header": {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        ".notes-content": {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '0 8px',
          gap: 8,
          ".notes-head": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          },
          ".notes-body": {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            ".date-time": {
              ".month": {
                paddingRight: 8,
                marginRight: 8,
                borderRight: '1px solid',
                borderColor: theme.palette.grey[100]
              }
            }
          },
          ".notes-footer": {
            textAlign: 'right',
            width: '100%'
          }
        }
      },
    },
}));

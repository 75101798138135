import { useTheme } from "@mui/material";
import { Typography, Grid, Dialog, Box, Select, Button, Textarea } from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { AddDecisionDialogWrap } from "./add-decision-dialog.style"
import { Stack } from "@src/components/stack";

export const AddDecisionDialog = ({ open, closeDialog }) => {
  const theme = useTheme();

  return (
    <>
      <Popup>
        <Dialog
          theme={theme}
          size={"lg"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Add Decision"}
          disablePortal
          noFooter
        >
          <AddDecisionDialogWrap
            theme={theme}
            className="add-decision-dialog-wrap"
          >
            <Box theme={theme} pl={2} pr={2}>
              <Grid
                theme={theme}
                container
                rowSpacing={0}
                columnSpacing={2}
                sx={{ mt: 0 }}

              >
                <Grid item xs={6} theme={theme}>
                  <Stack title="Add Decision" paddingMd={2} paddingXs={2} bgLight></Stack>
                  <Box theme={theme} className="custom-dropdown" mt={2}>
                    <Typography
                      theme={theme}
                      className="text-body2 text-muted"
                      variant="body2"
                      component={"span"}
                      display={"block"}
                    >
                      Comment Type:
                    </Typography>
                    <Select
                      theme={theme}
                      disablePortal={false}
                      items={[
                        {
                          text: "General",
                          value: "all",
                        },
                      ]}
                      onChange={() => { }}
                      value={"all"}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </Box>
                  <Box theme={theme} mt={2}>
                    <Select
                      theme={theme}
                      disablePortal={false}
                      items={[
                          {
                          text: "Recommendation",
                          value: "recommendation",
                          },
                      ]}
                      onChange={() => {}}
                      value={"sort"}
                      anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                      }}
                      transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                      }}
                    />
                  </Box>
                  <Box theme={theme} mt={2}>
                    <Textarea theme={theme} id="" type="text" fullWidth textCount={"10"} totalTextCount={"60"} rows={4} label="Comments"></Textarea>
                  </Box>
                  <Box theme={theme} textAlign={'center'} mb={2} mt={2}>
                    <Button theme={theme} primary text={'Send Decision'} />
                  </Box>
                </Grid>
                <Grid item xs={6} theme={theme}>
                  <Stack title="Add Stipulations" paddingMd={2} paddingXs={2} bgLight></Stack>
                  <Box theme={theme} className="custom-dropdown" mt={2}>
                    <Typography
                      theme={theme}
                      className="text-body2 text-muted"
                      variant="body2"
                      component={"span"}
                      display={"block"}
                    >
                      Comment Type:
                    </Typography>
                    <Select
                      theme={theme}
                      disablePortal={false}
                      items={[
                        {
                          text: "General",
                          value: "all",
                        },
                      ]}
                      onChange={() => { }}
                      value={"all"}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </Box>
                  <Box theme={theme} mt={2}>
                    <Select
                      theme={theme}
                      disablePortal={false}
                      items={[
                          {
                          text: "Recommendation",
                          value: "recommendation",
                          },
                      ]}
                      onChange={() => {}}
                      value={"sort"}
                      anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                      }}
                      transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                      }}
                    />
                  </Box>
                  <Box theme={theme} mt={2}>
                    <Textarea theme={theme} id="" type="text" fullWidth textCount={"10"} totalTextCount={"60"} rows={4} label="Comments"></Textarea>
                  </Box>
                  <Box theme={theme} textAlign={'center'} mb={2} mt={2}>
                    <Button theme={theme} primary text={'Send Stipulation'} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </AddDecisionDialogWrap>
        </Dialog>
      </Popup>
    </>
  );
};

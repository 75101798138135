import { useTheme } from "@mui/material"
import { RiskAssessmentStackWrap } from "./risk-assessment-graph.style"
import { Image, Box, Typography, Label } from "@ntpkunity/controls"
import graph from "@src/assets/icons/assessment-graph.svg"

export const RiskAssessmentGraph = () => {
    const theme = useTheme()

    return(
        <>
            <RiskAssessmentStackWrap theme={theme} className="risk-assessment-wrap">
                <Box theme={theme} mb={2} mt={1}>
                    <Label
                        theme={theme}
                        text="Risk Tier 2"
                        state="primary"
                        textTransform="none"
                    />
                </Box>
                <Box theme={theme} className="graph">
                    
                    <Image theme={theme} src={graph} alt={graph} />
                    <Box theme={theme} className="graph-text">
                        <Typography theme={theme} variant="caption" component={"small"}>Credit Score</Typography>
                        <Box theme={theme} display={'flex'}>
                            <Typography theme={theme} variant="h4" component={"h4"} className="text-primary">754</Typography>
                            <Typography theme={theme} variant="h4" component={"h4"} className="text-muted">/<span>900</span></Typography>
                        </Box>
                    </Box>
                    <Box theme={theme} mt={2}>
                        <Label
                            theme={theme}
                            text="Excellent"
                            state="primary"
                            textTransform="none"
                        />
                    </Box>
                </Box>
            </RiskAssessmentStackWrap>
        </>
    )
}
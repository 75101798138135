import { useTheme } from "@mui/material"
import { ChatWrap } from "./chat.style"
import { Typography, Box, Textarea, Button, Icon } from "@ntpkunity/controls";
import sendIcon from "@assets/icons/icon-send.svg"
import voiceIcon from "@assets/icons/Icon-voice.svg"
import textIcon from "@assets/icons/Icon-text.svg"
import addIcon from "@assets/icons/Icon-add.svg"
import { useState } from "react";
import { sendChatMessage } from "@src/components/api/chatApi";
import clsx from "clsx"
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export const Chat = ({ onChatFocus, onCloseChat, chatInitiated }) => {
  const theme = useTheme();

  const { running_workflow_instance_id } = useSelector(
    (state: any) => state.workQueue
  );

  const [input, setInput] = useState("");
  const [messages, setMessages] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showHelpText, setShowHelpText] = useState(true);
  const [isChatActive, setIsChatActive] = useState(false);
  const [isCenter, setIsCenter] = useState(false);

  const sendMessage = async () => {
    if (!input.trim()) return;

    setIsCenter(true);
    setShowHelpText(false);
    if (messages.length === 0) setShowHelpText(false);

    const userMessage = { role: "user", content: input };
    setMessages([userMessage]);
    setInput("");
    setIsLoading(true);
    // setIsChatActive(true);

    let accumulatedContent = "";

    try {
      await sendChatMessage(input, running_workflow_instance_id, (chunk) => {
        accumulatedContent += chunk;

        setMessages((prevMessages) => {
          const lastMessage = prevMessages[1] || {
            role: "assistant",
            content: "",
          };
          const updatedAssistantMessage = {
            ...lastMessage,
            content: accumulatedContent,
          };
          return [prevMessages[0], updatedAssistantMessage];
        });
      });
    } catch (error) {
      console.error("Error fetching response:", error);
      setMessages([
        userMessage,
        {
          role: "assistant",
          content: "Failed to fetch response. Please try again.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
      setShowHelpText(true);
    }
  };

  const handleChatFocus = () => {
    setShowHelpText(true);
    onChatFocus();
  };

  const handleCloseChat = () => {
    setMessages([]);
    setShowHelpText(true);
    onCloseChat([]);
    setIsCenter(false);
  };

  return (
    <>
      <ChatWrap theme={theme} className="chat-wrap start-chat">
        <Box theme={theme} className="cross-btn" mb={2}>
          <Button
            theme={theme}
            defaultBtn
            className="icon-light"
            iconText={<Icon name="CloseBlack" />}
            onClick={handleCloseChat}
          />
        </Box>
        <Box
          theme={theme}
          className={clsx("chat-box-history", { center: isCenter })}
        >
          <Box theme={theme} className="chat-history">
            {messages.map((msg, index) => (
              <Box
                theme={theme}
                key={index}
                className={`message ${
                  msg.role === "user" ? "user-message" : "ai-message"
                }`}
              >
                <Typography theme={theme} variant={"body2"} component={"span"}>
                  {msg.content}
                </Typography>
              </Box>
            ))}

            {isLoading && (
              <Box theme={theme} className="typing-indicator">
                <Box theme={theme} className="dot"></Box>
                <Box theme={theme} className="dot"></Box>
                <Box theme={theme} className="dot"></Box>
              </Box>
            )}
          </Box>
          {showHelpText && messages.length === 0 && (
            <Box theme={theme} textAlign={"center"} className="ai-help-text">
              <Typography
                theme={theme}
                variant="h3"
                component={"h3"}
                mb={1}
                className="gradient-text"
              >
                Hi, I’m Check AI.
              </Typography>
              <Typography
                theme={theme}
                variant="body1"
                component={"span"}
                display={"block"}
                mb={1}
                className="text-muted"
              >
                How Can I Help You?
              </Typography>
            </Box>
          )}

          <Box theme={theme} className="chat-box">
            <Textarea
              theme={theme}
              type="text"
              placeholder="Start chat with CHECK AI.."
              id=""
              rows={2}
              fullWidth
              onChange={(e) => setInput(e)}
              value={input}
              onKeyDown={handleKeyDown}
              onFocus={handleChatFocus}
            />
            <Box theme={theme} className="chat-options">
              <Box theme={theme} className="chat-filters">
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={
                    <>
                      <img src={addIcon} />
                    </>
                  }
                />
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={
                    <>
                      <img src={textIcon} />
                    </>
                  }
                />
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={
                    <>
                      <img src={voiceIcon} />
                    </>
                  }
                />
              </Box>
              <Box theme={theme}>
                <Button
                  onClick={sendMessage}
                  theme={theme}
                  defaultBtn
                  iconText={
                    <>
                      <img src={sendIcon} />
                    </>
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </ChatWrap>
    </>
  );
};
import { useTheme } from "@mui/material"
import { ThirdPartyResponseListWrap } from "./third-party-response-list.style"
import { Icon, Box, Typography, Accordion, Label } from "@ntpkunity/controls"
import { Alert } from "@src/components"
import { PdfList } from "@src/components"


export const ThirdPartyResponseList = () => {
    const theme = useTheme()

    return(
        <>
            <ThirdPartyResponseListWrap theme={theme} className="third-party-response-list-wrap">
                <Accordion
                    theme={theme}
                    items={[
                    {
                        content: (
                        <>
                            <PdfList
                                title={'Sample_report.pdf'}
                                subTitle={'00-00-0000'}
                            />
                        </>
                        ),
                        title: <>Experian Report 
                        <Label 
                            theme={theme} 
                            text="Active"
                            textTransform="none" 
                        />
                        </>,
                        key: "experian-report",
                        isExpanded: true,
                        id: "experian-report",
                        ariaControls: "experian-report",
                    },
                    {
                        content: (
                        <>
                            <PdfList
                                title={'Sample_report.pdf'}
                                subTitle={'00-00-0000'}
                            />
                        </>
                        ),
                        title: <>TransUnion Report 
                            <Label 
                                theme={theme} 
                                text="Active"
                                textTransform="none" 
                            />
                        </>,
                        key: "transUnion-report",
                        isExpanded: false,
                        id: "transUnion-report",
                        ariaControls: "transUnion-report",
                    },
                    {
                        content: (
                        <>
                            <PdfList
                                title={'Sample_report.pdf'}
                                subTitle={'00-00-0000'}
                            />
                        </>
                        ),
                        title: <>Equifex Report 
                            <Label 
                                theme={theme} 
                                text="Active"
                                state="primary"
                                textTransform="none" 
                            />
                        </>,
                        key: "equifex-report",
                        isExpanded: false,
                        id: "equifex-report",
                        ariaControls: "equifex-report",
                    },
                    ]}
                    shouldCollapse
                />
            </ThirdPartyResponseListWrap>
            
        </>
    )
}
import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_WORKQUEUE_REQUEST,
  fetchWorkqueueSuccess,
  fetchWorkqueueFailure,
} from "../actions/workQueueActions";


function* fetchWorkQueuesSaga(action) {
  const { pageNumber, pageSize } = action.payload;
  try {
    const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/dms/order/workqueue?page_number=${pageNumber}&page_size=${pageSize}`,
      {
      headers: { "x-api-key": `${process.env.REACT_APP_API_KEY}` },
    });
    yield put(fetchWorkqueueSuccess(response.data));
  } catch (error) {
    console.error("Workqueues Saga Error:::::", error);
    yield put(fetchWorkqueueFailure(error.message));
  }
}

export function* watchWorkQueuesSaga() {
  yield takeLatest(FETCH_WORKQUEUE_REQUEST, fetchWorkQueuesSaga);
}
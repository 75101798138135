import { styled } from "@mui/material/styles";
import zIndex from "@mui/material/styles/zIndex";
import { CustomColors } from "@src/styles";

export const DataTableWrap: any = styled("div")(({ theme }) => ({
  "&.data-table-wrap": {
    ".u-table-wrap": {
      ".scroll": {
        ".scroll-hide": {
          backgroundColor: "#F8F8F9",
          height: 56,
          top: 0,
          right: 0,
          border: "none",
          borderRadius: "0 7px 8px 0",
          "&.spr-border": {
            "&::after": {
              display: "none",
            },
          },
        },
      },
      ".u-table-container": {
        border: "none !important",
        maxHeight: "calc(100vh - 200px)",
        ".u-table-head": {
          tr: {
            "&:nth-child(1)": {
              td: {
                backgroundColor: CustomColors.lightGrey,
                padding: "16px 8px 16px 20px",
                "&:nth-child(1)": {
                  borderBottomLeftRadius: 8,
                },
              },
            },
            td: {
              border: "none",
              fontWeight: theme.typography.fontWeightMedium,
              ".table-order": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 8,
                ".MuiButton-root.btn.with-icon.icon-only": {
                  padding: 0,
                },
              },
            },
            "&.filters-row": {
              td: {
                padding: "8px 8px 8px 20px",
                ".table-filter": {
                  ".u-form-group": {
                    ".u-form-control": {
                      height: 36,
                      borderRadius: 4,
                      "@media only screen and (min-width: 1599px)": {
                        height: 40,
                      }
                    },
                  },
                },
              },
            },
          },
        },
        ".u-table-body": {
          tr: {
            "&:nth-of-type(odd)": {
              td: {
                backgroundColor: CustomColors.lightGrey + " !important",
                "&:nth-child(1)": {
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                },
                "&:last-child": {
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                },
              },
            },
            td: {
              borderTop: "none !important",
              padding: "16px 20px !important",
              maxWidth: "100%",
              color: CustomColors.bodyColor,
              lineHeight: '20px',
              "&.unread-email": {
                position: 'relative',
                "&::before": {
                  display: "block",
                  content: '" "',
                  width: 4,
                  height: 24,
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 8,
                  position: "absolute",
                  left: 0,
                  top: 14,
                },
              },
              img: {
                marginLeft: 8,
              },
              "@media only screen and (min-width: 1599px)": {
                lineHeight: '24px',
                fontSize: theme.typography.body1.fontSize
              }
            },
            "&:hover": {
              "&:nth-of-type(even)": {
                td: {
                  "&:nth-child(1)": {
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                  },
                  "&:last-child": {
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  },
                },
              },
              td: {
                backgroundColor: '#E5E9F5 !important'
              }
            },
          },
        },
      },
      "&.has-pagination": {
        ".pagination-wrap": {
          ".MuiTablePagination-root": {
            borderColor: theme.palette.grey[100] + " transparent transparent transparent",
            borderTopStyle: "solid",
            borderTopWidth: 1,
            ".MuiToolbar-root": {
              padding: "4px 12px",
              height: 40,
              "@media only screen and (min-width: 1599px)": {
                padding: "16px 12px",
                height: 'auto',
              },
              ".MuiTablePagination-actions": {
                ".MuiIconButton-root": {
                  padding: 0
                }
              }
            },
          },
        },
      }
    },
    "&.customize-table": {
      ".u-table-wrap": {
        ".scroll": {
          display: 'none',
          ".scroll-hide": {
            height: 104,
            borderRadius: '0 20px 8px 0',
            "@media(min-width: 1599.95px)": {
              height: 114,
            }
          }
        },
        ".u-table": {
          ".u-table-head": {
            backgroundColor: theme.palette.common.white,
            tr: {
              td: {
                "&.w-90": {
                  width: 90,
                  minWidth: 90
                },
                "&.w-px-100": {
                  width: 100,
                  minWidth: 100
                },
                "&.w-144": {
                  width: 144,
                  minWidth: 144
                },
                "&.w-150": {
                  width: 150,
                  minWidth: 150
                },
                "&.w-180": {
                  width: 180,
                  minWidth: 180
                },
                "&.w-250": {
                  width: 250,
                  minWidth: 250
                },
                "&.w-200": {
                  width: 200,
                  minWidth: 200
                },
                "&.w-300": {
                  width: 300,
                  minWidth: 300
                },
                maxWidth: 400,
                borderBottom: '1px solid',
                borderColor: '#F5F5F7',
                borderRadius: 0,
              },
              "&:nth-child(1)": {
                td: {
                  backgroundColor: theme.palette.common.white,
                },
              },
            }
          },
          ".u-table-body": {
            tr: {
              "&.accordion-row": {
                td: {
                  borderBottom: '1px solid',
                  borderColor: '#F5F5F7',
                }
              },
              "&:nth-of-type(odd)": {
                td: {
                  backgroundColor: theme.palette.common.white + '!important',
                },
              },
              td: {
                "&.w-90": {
                  width: 90,
                  minWidth: 90
                },
                "&.w-px-100": {
                  width: 100,
                  minWidth: 100
                },
                "&.w-144": {
                  width: 144,
                  minWidth: 144
                },
                "&.w-150": {
                  width: 150,
                  minWidth: 150
                },
                "&.w-180": {
                  width: 180,
                  minWidth: 180
                },
                "&.w-250": {
                  width: 250,
                  minWidth: 250
                },
                "&.w-200": {
                  width: 200,
                  minWidth: 200
                },
                "&.w-300": {
                  width: 300,
                  minWidth: 300
                },
                maxWidth: 400,
                ".custom-checkbox-wrap": {
                  ".u-custom-control-label": {
                    margin: 0,
                    ".custom-checkbox": {
                      margin: 0
                    }
                  }
                },
                ".toggle-btn": {
                  transform: "rotate(0deg)",
                  webkitTransform: "rotate(0deg)",
                  "&.ch-down": {
                    transform: "rotate(180deg)",
                    webkitTransform: "rotate(180deg)",
                  },
                },
                "&.editable-cell": {
                  ".cell-content": {
                    ".u-form-group": {
                      ".u-form-control": {
                        height: 40,
                        ".MuiInputBase-input": {
                          padding: '12px 16px'
                        }
                      }
                    }
                  }
                },
                
              }
            }
          },
          tr: {
            td: {
              padding: '12px 16px !important',
              fontSize: '14px !important',
              lineHeight: '20px !important',
              borderRight: '1px solid',
              borderColor: '#F5F5F7',
              borderRadius: 0,
              "&.text-wrap-normal": {
                whiteSpace: 'normal',
              },
              ".multi-line-ellipsis": {
                display: '-webkit-box',
                '-webkit-line-clamp': '2', /* Number of lines to display */
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
        }
      }
    }
  }
}
    }
  },

"@media only screen and (min-width: 1599px)": {
  ".u-table-wrap .u-table .u-table-head td": {
    fontSize: 14,
      lineHeight: '20px !important'
  },
},
}));

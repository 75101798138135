import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FinanceStructureModifyDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.finance-structure-modify-popup-wrap": {
    padding: 8,
    ".stack-wrap": {
      ".stack-header": {
        padding: '0 4px'
      }
    },
    '.scrollable-container': {
      maxHeight: 'calc(100vh - 333px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      "@media only screen and (min-width: 1599.95px)": {
        maxHeight: 'calc(100vh - 184px)',
      }
    },
    ".left-side": {
      minWidth: '195px',
      maxWidth: '211px',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      gap: 8,
      ".list": {
        padding: 8,
        height: 40
      }
    },
    ".right-side": {
      ".scrollable-containerX": {
        maxHeight: 'calc(100vh - 0px)',
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollbarWidth: 'none',
      },
      ".custom-checkbox-wrap": {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ".u-custom-control-label": {
          ".MuiFormControlLabel-label": {
            color: '#474A59',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '65%'
          }
        }
      },
      ".u-form-group": {
        marginBottom: 8,
        ".u-form-control": {
          backgroundColor: "#F7F7F7",
          ".MuiInputBase-input": {
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.body1.fontWeight,
            lineHeight: theme.typography.body1.lineHeight,
            color: '#474A59',
            padding: 8,
          },
          "&.MuiInputBase-adornedStart": {
            paddingLeft: 8,
            ".adornment-icon": {
              "&.start-icon": {
                marginRight: 0,
                fontSize: theme.typography.body1.fontSize,
                fontWeight: theme.typography.body1.fontWeight,
                lineHeight: theme.typography.body1.lineHeight,
                color: '#474A59'
              }
            }
          },
          fieldset: {
            border: 'none'
          }
        }
      },
    }
  },
}));

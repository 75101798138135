import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { DocumentsListWrap } from "./document-list.style";
import { Box } from "@ntpkunity/controls";
import { PdfList } from "@src/components";

interface Document {
  id: number;
  created_at: string;
  updated_at: string;
  identifier: string;
  document_name?: string;
  document_url?: string;
  document_type: string;
}

interface DocumentNested {
  id: number;
  document_name?: string;
  template?: string;
  created_at: string;
  document_url?: string;
  document_type: string;
}

export const TEMPLATE_ENDPOINTS = {
  GETPRESIGNED_URL: "configuration/generate-presigned-url",
};

function truncateFileName(fileName: String) {
  if (!fileName) return fileName;
  const dotIndex = fileName.lastIndexOf(".");
  if (dotIndex === -1) return fileName;
  const namePart = fileName.substring(0, dotIndex);
  const extension = fileName.substring(dotIndex);
  
  const truncatedName = namePart.length > 18 ? `${namePart.substring(0, 18)}...` : namePart;
  return truncatedName + extension;
}

export const DocumentsList = ({ documents }) => {
  const theme = useTheme();
  const [allDocuments, setAllDocuments] = useState<Document[]>([]);

  useEffect(() => {
    if (documents && Array.isArray(documents)) {
      setAllDocuments(documents);
    }
  }, [documents]);

  const handleDownloadTemplate = async (template_name: string) => {
    if (!template_name) {
      console.error("Template name is required");
      return;
    }

    const formattedTemplateName = template_name.replace(/\s+/g, "_");

    try {
      const response = await fetch(
        `https://flex-reporting-dev.netsolapp.io/documents/${TEMPLATE_ENDPOINTS.GETPRESIGNED_URL}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY ?? ""
          },
          body: JSON.stringify({
            key: formattedTemplateName,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch template: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.template_url) {
        window.open(data.template_url, "_blank");
      } else {
        console.error("Invalid response: template_url not found");
      }
    } catch (error) {
      console.error("Error downloading template:", error);
    }
  };

  return (
    <>
      <DocumentsListWrap
        theme={theme}
        className="document-list-wrap scrollable-container"
      >
        <Box theme={theme} className="documents">
          {allDocuments
            ?.filter((doc) => doc.document_name !== null)
            .map((document: DocumentNested, i) => {
              const isPdf = document?.document_name
                ?.toLowerCase()
                .endsWith(".pdf");
              return (
                <PdfList
                  key={i}
                  title={truncateFileName(document?.document_name || "test.pdf").toString()}
                  subTitle={document?.created_at?.split("T")[0]}
                  variant="light"
                  onClick={() => {
                    handleDownloadTemplate(
                      document?.document_name || "default.pdf"
                    );
                  }}
                  pdfIcon={isPdf}
                  jpgIcon={!isPdf}
                />
              );
            })}
        </Box>
      </DocumentsListWrap>
    </>
  );
};

import { useTheme } from "@mui/material";
import { Box, Dialog, Icon, Grid, Typography, Checkbox, Input, Button } from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { FinanceStructureModifyDialogWrap } from "./finance-structure-modify-popup-dialog.style"
import { Stack } from "@src/components/stack";

export const FinanceStructureModifyDialog = ({ open, closeDialog }) => {
  const theme = useTheme();

  return (
    <>
      <Popup>
        <Dialog
          theme={theme}
          size={"lg"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Add/Modify Structure"}
          disablePortal
          noFooter
        >
          <FinanceStructureModifyDialogWrap theme={theme} className="finance-structure-modify-popup-wrap">
            <Stack bgLight paddingMd={1.5} paddingXs={1.5} title={'Finance Structure'}
              actionArea={<>
                <Box theme={theme} display={'flex'} alignItems={'center'}>
                  <Icon name="AddIcon" className="text-primary" />
                  <a href="" className="text-primary">Add New Structure</a>
                </Box>
              </>}>
            </Stack>
            <Stack paddingMd={2} paddingXs={2}>
              <Grid
                theme={theme}
                container
                rowSpacing={0}
                columnSpacing={2}
                sx={{ mt: 0 }}
              >
                <Grid item xs={12} theme={theme}>
                  <Grid
                    theme={theme}
                    container
                    rowSpacing={0}
                    columnSpacing={2}
                    sx={{ mt: 0 }}
                  >
                    <Grid item xs={3.5} theme={theme}>
                      <Box theme={theme} className="left-side"></Box>
                    </Grid>
                    <Grid item xs={8.5} theme={theme}>
                      <Box theme={theme} className="right-side">
                        <Grid
                          theme={theme}
                          container
                          rowSpacing={0}
                          columnSpacing={2}
                          sx={{ mt: 0 }}
                        >
                          <Grid item xs={4} theme={theme}>
                            <Checkbox theme={theme} label={'Requested'} />
                          </Grid>
                          <Grid item xs={4} theme={theme}>
                            <Checkbox theme={theme} label={'AI Recommendation'} />
                          </Grid>
                          <Grid item xs={4} theme={theme}>
                            <Checkbox theme={theme} label={'Underwriter Recommendation'} />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} theme={theme} className="scrollable-container">
                  <Grid
                    theme={theme}
                    container
                    rowSpacing={0}
                    columnSpacing={2}
                    sx={{ mt: 0 }}
                  >
                    <Grid item xs={3.5} theme={theme} className="">
                      <Box theme={theme} className="left-side">
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Finance Type</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Vehicle Price</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Vehicle Price</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Rebate</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Discount</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Sales Tax</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Down Payment</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Deferred Down Payment</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Net Trade-in Value</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Total Vehicle Sales Price</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">GAP</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Warranty</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Insurance</Typography>
                        <Typography theme={theme} variant="body2" component={'span'} className="list text-body2 fw-medium">Backend Fees</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={8.5} theme={theme}>
                      <Box theme={theme} className="right-side">
                        <Grid
                          theme={theme}
                          container
                          rowSpacing={0}
                          columnSpacing={2}
                          sx={{ mt: 0 }}
                          className="scrollable-containerX"
                        >
                          <Grid item xs={4} theme={theme}>
                            <Input
                              theme={theme}
                              type="text"
                              value={"Lease"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                          </Grid>
                          <Grid item xs={4} theme={theme}>
                            <Input
                              theme={theme}
                              type="text"
                              value={"Lease"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                          </Grid>
                          <Grid item xs={4} theme={theme}>
                            <Input
                              theme={theme}
                              type="text"
                              value={"Lease"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={"$"}
                              value={"N/A"}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} theme={theme} textAlign={'center'} pt={2}>
                  <Button theme={theme} primary text={'Add Finance Structure'} />
                </Grid>
              </Grid>
            </Stack>
          </FinanceStructureModifyDialogWrap>
        </Dialog>
      </Popup>
    </>
  );
};

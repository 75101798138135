
import { useTheme } from "@mui/material"
import { MyNotesWrap } from "./my-notes.style"
import { Stack } from "@src/components/stack";
import { Button, Icon, Box } from "@ntpkunity/controls";
import { MyNotesList} from "../my-notes-list/my-notes-list.component"
import { useState } from "react";
import { StickyNotesPopup } from "@src/components/dashboard/sticky-notes-stack/sticky-notes-popup/sticky-notes-popup.component" 

export const MyNotes = () => {
    const theme = useTheme();

    const [open, setOpen] = useState(false); 

    const handleClick = () => {
      setOpen(!open); 
    };

    return(
        <>
            <MyNotesWrap theme={theme} className="my-notes-wrap">
                <Stack bgLight paddingMd={2} paddingXs={2} title="My Notes" className="my-notes-stack" fullWidth
                    actionArea={<>
                        <Button 
                            theme={theme}
                            defaultBtn
                            text={'Add Notes'}
                            endIcon={<><Icon name="AddIcon" /></>}
                            onClick={handleClick}
                        />
                    </>}>
                    <Button 
                        theme={theme}
                        primary
                        text={'Filters'}
                        startIcon={<><Icon name="FilterIconV4" /></>}
                    />
                    <Box theme={theme} className="scrollable-container">
                        <Box theme={theme} className="notes-listing">
                            <MyNotesList />
                            <MyNotesList />
                            <MyNotesList />
                            <MyNotesList />
                            <MyNotesList />
                            <MyNotesList />
                        </Box>
                    </Box>
                </Stack>
            </MyNotesWrap>
            <StickyNotesPopup open={open} closeDialog={handleClick} />
        </>
    )
}
import { useTheme } from "@mui/material";
import { Box, Button, CircleLoader, Grid, Icon, Label } from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { RiskAssessmentGraph } from "../risk-assessment-graph/risk-assessment-graph"
import { AlertStatusText, BasicStack } from "@src/components/dashboard"
import { Stack } from "@src/components/stack";
import { useEffect, useState } from "react";
import { RiskAssessmentDialog } from "../risk-assessment-dialog/risk-assessment-dialog.component"
import { useDispatch, useSelector } from "react-redux";
import { closeRiskDialog, fetchRiskAssessmentRequest, openRiskDialog } from "@src/components/redux/actions/riskAssessmentActions";

export const RiskAssessmentStack = () => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const dispatch = useDispatch();
  const { loading, riskAssessmentData, error, isDialogOpen } = useSelector(
    (state: any) => state.riskAssessment
  );

  // useEffect(() => {
  //   if (!riskAssessmentData) {
  //     dispatch(fetchRiskAssessmentRequest());
  //   }
  // }, [dispatch, riskAssessmentData]);

  useEffect(() => {
    const customerReferenceId = "U-H000D3UA";
    if (!riskAssessmentData) {
      dispatch(fetchRiskAssessmentRequest(customerReferenceId));
    }
  }, [dispatch, riskAssessmentData]);

  if (loading) return <CircleLoader theme={theme} size="sm" />;
  if (error) return <div>Error: {error}</div>;

  // const {
  //   credit_rating,
  //   net_finance_amount,
  //   estimated_monthly_payment,
  //   due_at_signing,
  // } = riskAssessmentData?.primaryApplicant ?? {};

  const handleOpenDialog = () => {
    // setOpened(true);
    dispatch(openRiskDialog());
  };

  const handleCloseDialog = () => {
    // setOpened(false);
    dispatch(closeRiskDialog());
  };

  return (
    <>
      <Stack
        title={"Risk Assessment Metrices"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={
              <>
                <Icon name="ArrowLinkIcon" />
              </>
            }
            onClick={handleOpenDialog}
          />
        }
      >
        <Box theme={theme} display={'flex'} flexDirection={'column'} gap={1}>
          {loading ? (
            <CircleLoader theme={theme} size="sm" />
          ) : (
            <>
              {/* Risk Assessment Graph */}
            <RiskAssessmentGraph />

                {/* Fraud Check Alert */}
                <AlertStatusText
                  text={"Fraud Check"}
                  statusText={riskAssessmentData?.primaryApplicant?.creditRating ?? "N/A"}
                  success={riskAssessmentData?.primaryApplicant?.creditRating === "Approved"}
                  fullWidth
                />

                {/* Monthly Income */}
                <BasicStack
                  text={"Monthly Income:"}
                  subTitle={`${riskAssessmentData?.primaryApplicant?.loanDetails?.financeAmount?.toLocaleString() ?? "N/A"}`}
                  fullWidth
                />

                {/* Monthly Debt Payment */}
                <BasicStack
                  text={"Monthly Debt Payment"}
                  subTitle={`${riskAssessmentData?.primaryApplicant?.monthlyIncome?.toLocaleString() ?? "N/A"}`}
                  fullWidth
                />

                {/* Current Exposure with Action Button */}
                <BasicStack
                  text={"Current Exposure:"}
                  subTitle={`${riskAssessmentData?.primaryApplicant?.dueAtSigning?.toLocaleString() ?? "N/A"}`}
                  fullWidth
                  actionArea={
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="ArrowLinkIcon" />}
                    onClick={handleOpenDialog}
                    />
                }
            />

              {/* Credit Utilisation */}
              <Alert
                text={'Credit Utilisation:'}
                value={'30%'}
                success
                fullWidth
              />

              {/* DTI Ratio with Error Alert */}
              <Alert
                text={'DTI Ratio:'}
                value={'45%'}
                error
                fullWidth
              />

              {/* LTV Ratio with Error Alert */}
              <Alert
                text={'LTV Ratio:'}
                value={'80%'}
                error
                fullWidth
              />
            </>
          )}
        </Box>
      </Stack>
      <RiskAssessmentDialog open={isDialogOpen} closeDialog={handleCloseDialog} />
    </>
  );
};


import { useTheme } from "@mui/material";
import { Box, Button, Grid, Icon, Label } from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { AlertStatusText, BasicStack } from "@src/components/dashboard"
import { ThirdPartyResponseList } from "../third-party-response-list/third-party-response-list"
import { ThirdPartyResponseDialog } from "../third-party-response-dialog/third-party-response-dialog/third-party-response-dialog.component"
import { Stack } from "@src/components/stack";
import { useState } from "react";
import Transunion_Report from "@src/assets/docs/TransUnion.pdf.pdf";

export const ThirdPartyResponse = () => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpened(true);
  };

  const handleCloseDialog = () => {
    setOpened(false);
  };

  const getTimestampWithGap = (monthsGap) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + monthsGap);
    return currentDate.toISOString().split("T")[0];
  };

  const handleOpenTransUnionReport = () => {
    window.open(Transunion_Report, "_blank");
  };

  return (
    <>
      <Stack
        title={"Third Party Response"}
        paddingXs={2}
        paddingMd={2}
      >
        <Box theme={theme} display={'flex'} flexDirection={'column'} gap={1} mt={2}>
          <ThirdPartyResponseList title={'Fraud Check'} status="primary" onClick={handleOpenDialog} />
          <ThirdPartyResponseList title={'TransUnion Report'} status="primary" onClick={handleOpenTransUnionReport} />
          <ThirdPartyResponseList title={'Experian Report'} status="info" />
        </Box>
        <ThirdPartyResponseDialog open={open} closeDialog={handleCloseDialog} />
      </Stack>
    </>
  );
};

import { BlurCircular } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { Box, Grid, Typography, CircleLoader, Accordion } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import moment from "moment";
import { useSelector } from "react-redux";

export const ApplicantDetailsList = () => {
  const theme = useTheme();
  const { applicantDetails, dialogLoading, error } = useSelector((state: any) => state.applicantDetails);

  const companyInfo = applicantDetails?.customer_info || {};
  const companyLicense = applicantDetails?.company_license?.[0] || {};
  const companyAddress = companyInfo?.company_addresses?.[0] || {};

  const applicantName = `${
    applicantDetails?.customer_info?.first_name ?? "--"
    } ${applicantDetails?.customer_info?.last_name ?? "--"}`;

  const dateOfBirth = companyLicense.date_of_birth
    ? new Date(companyLicense.date_of_birth).toLocaleDateString()
    : "--";

  const formattedDate = (date) => (date ? moment(date).format("MM/DD/YYYY") : "--");

  const getYearsMonths = (date) => {
    if (!date) return "--";
    const startDate = moment(date);
    const now = moment();
    const years = now.diff(startDate, "years");
    const months = now.diff(startDate, "months") % 12;
    return `${years} years ${months} months`;
  };

  const basicDetailsFields = [
    {
      title: "Client Type:",
      textRight: true,
      subtitle: companyInfo?.customer_type || "--",
    },
    {
      title: "Company Name:",
      textRight: true,
      subtitle: companyInfo?.company_name || "--",
    },
    {
      title: "Company Registration:",
      textRight: true,
      subtitle: companyInfo?.registration_number || "--",
    },
    {
      title: "Trading As:",
      textRight: true,
      subtitle: companyInfo?.trading_as || "--",
    },
    {
      title: "SIC Code:",
      textRight: true,
      subtitle: companyInfo?.sic_code || "--",
    },
    {
      title: "Nature of Business:",
      textRight: true,
      subtitle: companyInfo?.nature_of_business || "--",
    },
    {
      title: "Business Age:",
      textRight: true,
      subtitle: getYearsMonths(companyInfo?.year_business_established),
    },
  ];

  const mailingAddress = [
    {
      title: "Current Address:",
      textRight: true,
      subtitle: companyAddress?.address_line_1 || "--",
    },
    {
      title: "City:",
      textRight: true,
      subtitle: companyAddress?.city || "--",
    },
    {
      title: "Zip Code:",
      textRight: true,
      subtitle: companyAddress?.zip_code || "--",
    },
    {
      title: "County:",
      textRight: true,
      subtitle: companyAddress?.county || "--",
    },
    {
      title: "Living Since:",
      textRight: true,
      subtitle: getYearsMonths(companyAddress?.move_in_date),
    },
  ];

  const billingAddress = [
    {
      title: "Address:",
      textRight: true,
      subtitle: companyInfo?.billing_address?.address_line_1 || "--",
    },
    {
      title: "City:",
      textRight: true,
      subtitle: companyInfo?.billing_address?.city || "--",
    },
    {
      title: "Zip Code:",
      textRight: true,
      subtitle: companyInfo?.billing_address?.zip_code || "--",
    },
    {
      title: "County:",
      textRight: true,
      subtitle: companyInfo?.billing_address?.county || "--",
    },
    {
      title: "Living Since:",
      textRight: true,
      subtitle: getYearsMonths(companyInfo?.billing_address?.move_in_date),
    },
  ];

  const incomeDetailsFields = [
    {
      title: "Average Balance From Bank Statement:",
      textRight: true,
      subtitle: companyInfo?.employment_info?.bank_statement_balance || "--",
    },
    {
      title: "Net Monthly Income After Tax:",
      textRight: true,
      subtitle:
        companyInfo?.employment_info?.annual_employment_income
          ? `$${(companyInfo.employment_info.annual_employment_income / 12).toLocaleString()}`
          : "--",
    },
  ];


  if (dialogLoading) {
    return <CircleLoader theme={theme} size="sm" />;
  }

  if (error) {
    return (
      <Box theme={theme} textAlign="center" mt={2}>
        <Typography theme={theme} variant="body2" component="p" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid
      theme={theme}
      container
      rowSpacing={0}
      columnSpacing={2}
      sx={{ mt: 0 }}
    >
      {/* Basic Details */}
      <Grid item xs={6} theme={theme}>
        <Stack paddingMd={0} paddingXs={0}>
          <Typography
            theme={theme}
            variant="subtitle2"
            component={"span"}
            className="fw-sbold"
            p={1}
          >
            Basic Details
          </Typography>
          <BasicList noWrap options={basicDetailsFields} />
        </Stack>
      </Grid>

      {/* Address Details */}
      <Grid item xs={6} theme={theme}>
        <Stack paddingMd={0} paddingXs={0}>
          <Typography
            theme={theme}
            variant="subtitle2"
            component={"span"}
            className="fw-sbold"
            p={1}
          >
            Address
          </Typography>
          <Box theme={theme} className="accordian-mini-wrap" mt={2}>
            <Accordion
                theme={theme}
                items={[
                  {
                    content: (
                      <>
                        <BasicList noWrap options={mailingAddress} />
                      </>
                    ),
                    title: "Mailing Address",
                    key: "mailing-address",
                    isExpanded: false,
                    id: "mailing-address",
                    ariaControls: "mailing-address",
                  },
                  {
                    content: (
                      <>
                        <BasicList noWrap options={billingAddress} />
                      </>
                    ),
                    title: "Billing Address",
                    key: "billing-address",
                    isExpanded: false,
                    id: "billing-address",
                    ariaControls: "billing-address",
                  },
                ]}
                shouldCollapse
              />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

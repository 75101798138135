import { useTheme } from "@mui/material";
import { Box, Grid, Typography, Accordion } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import { useSelector } from "react-redux";

export const ApplicantGuarantorList = () => {
  const theme = useTheme();

  // Dynamic fields for different sections

  const basicDetailsFields = [
    {
      title: 'Client Type:',
      textRight: true,
      subtitle: 'Limited Company'
    },
    {
      title: 'Company Name:',
      textRight: true,
      subtitle: 'ABC Organisation'
    },
  ]

  return (
    <>
      <Grid theme={theme} container rowSpacing={0} columnSpacing={2} sx={{ mt: 0 }}>
        {/* Basic Details */}
        <Grid item xs={6} theme={theme}>
          <Stack paddingMd={0} paddingXs={0}>
            <Typography theme={theme} variant="subtitle2" component={"span"} className="fw-sbold" p={1}>
              Basic Details
            </Typography>
            <BasicList noWrap options={basicDetailsFields} />
          </Stack>
        </Grid>

        {/* Address Details */}
        <Grid item xs={6} theme={theme}>
          <Stack paddingMd={0} paddingXs={0}>
            <Typography theme={theme} variant="subtitle2" component={"span"} className="fw-sbold" p={1}>
              Address
            </Typography>
            <BasicList noWrap options={[
              {
                title: 'Current Address:',
                textRight: true,
                subtitle: 'Mr John Smith. 132, My Street, Kingston, New York 12401'
              },
              {
                title: 'City:',
                textRight: true,
                subtitle: 'Driving License'
              },
              {
                title: 'State:',
                textRight: true,
                subtitle: '2340-5892043-5'
              },
              {
                title: 'Zip Code:',
                textRight: true,
                subtitle: '12-2030'
              },
              {
                title: 'County:',
                textRight: true,
                subtitle: '04-00-0000'
              },
              {
                title: 'Living Since:',
                textRight: true,
                subtitle: '38 Yrs.'
              },
            ]} />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}; 